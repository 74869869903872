import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import UserService from "./services/UserService";
import HttpClient from "./services/HttpClient";
import LanguageService from "./services/LanguageService";

//import reportWebVitals from './reportWebVitals';
//https://tariqul-islam-rony.medium.com/internationalization-localization-with-react-js-65d6f6badd56

const container = document.getElementById("root");
const root = createRoot(container);
const languageService = LanguageService.initLanguage();

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={languageService}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </React.StrictMode>,
  );

UserService.initKeycloak(renderApp);
HttpClient.configure();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
