import React from "react";
import EasyTrans from "../bundel/EasyTrans";

const RegisterWorkConfirmation = (props) => {
  return (
    <div className=" max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                <EasyTrans>RegisterWork.Confirmation.Title</EasyTrans>
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                <EasyTrans>RegisterWork.Confirmation.Caption</EasyTrans>
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <fieldset>
                    <div>
                      <p className="text-sm text-gray-500"></p>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="right"
                          name="right"
                          type="checkbox"
                          text=""
                          required={true}
                          className={`focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300 ${
                            props.confirmationError
                              ? "border-2 border-red-700 bg-red-50"
                              : ""
                          }`}
                          onChange={(event) => {
                            props.onSetConfirmation(event.target.checked);
                            props.setConfirmationError(false);
                          }}
                        />
                        <label className="ml-3 block text-sm font-medium text-gray-700">
                          <EasyTrans>RegisterWork.Confirmation.Text</EasyTrans>
                          <span className="text-red-600">*</span>
                        </label>
                      </div>
                      {props.confirmationError ? (
                        <span className="text-red-700">
                          <EasyTrans>
                            RegisterWork.Confirmation.Confirmation
                          </EasyTrans>
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fairblue-500 hover:bg-fairdarkgreen hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={props.onSendRegister}
                  >
                    <EasyTrans>RegisterWork.Register</EasyTrans>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default RegisterWorkConfirmation;
