import EasyTrans from "./EasyTrans";
import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-fairgray md:px-6 md:py-8 border-t-2 border-fairblue-500">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center mb-4 sm:mb-0">
          <span className="self-center text-2xl font-semibold nowrap text-fairblue-500">
            fairregister
          </span>
        </div>
        <ul className="flex flex-wrap items-center mb-6 text-sm text-fairblue-500-500 sm:mb-0">
          <li>
            <a
              href="https://www.fairkom.eu/about"
              className="px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md"
            >
              <EasyTrans>Footer.FooterAboutUs</EasyTrans>
            </a>
          </li>
          <li>
            <a
              href="https://www.fairkom.eu/privacy"
              className="px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md"
            >
              <EasyTrans>Footer.FooterDataPrivacy</EasyTrans>
            </a>
          </li>
          <li>
            <a
              href="https://www.fairkom.eu/imprint"
              className="px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md"
            >
              <EasyTrans>Footer.FooterImprint</EasyTrans>
            </a>
          </li>
          <li>
            <a
              href="https://www.fairkom.eu/contact"
              className="text-fairblue-500 hover:bg-fairblue-500 hover:text-fairblue-500 px-3 py-2 rounded-md text-sm font-medium"
            >
              <EasyTrans>Footer.FooterContact</EasyTrans>
            </a>
          </li>
        </ul>
      </div>
      <hr className="my-6 border-fairblue-500 sm:mx-auto lg:my-8" />
      <span className="block text-sm text-fairblue-500 sm:text-center">
        <EasyTrans>Footer.FooterAProjectBy</EasyTrans>&nbsp;
        <a href="https://www.fairkom.eu/" className="hover:underline">
          fairkom
        </a>
      </span>
    </footer>
  );
};

export default Footer;
