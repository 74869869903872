import React from "react";
import { XIcon, PlusIcon } from "@heroicons/react/outline";

const AddAndDeleteButton = (props) => {
  return (
    <div className="shadow mb-5 overflow-hidden sm:rounded-md">
      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
        {props.deleteAllowed ? (
          <div className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
            <div
              className="cursor-pointer flex"
              onClick={() => {
                props.onDelete();
              }}
            >
              <XIcon className="flex-initial h-6 w-6" aria-hidden="true" />
              {props.nameDelete}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
          <div
            className="cursor-pointer flex"
            onClick={() => {
              props.onAdd();
            }}
          >
            <PlusIcon className="flex-initial h-6 w-6" aria-hidden="true" />
            {props.nameAdd}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAndDeleteButton;
