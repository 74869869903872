import React, { useState, useEffect } from "react";
import cclogo from "./../../../assets/cc/cc.xlarge.png";
import bylogo from "./../../../assets/cc/by.xlarge.png";
import nceulogo from "./../../../assets/cc/nc-eu.xlarge.png";
import salogo from "./../../../assets/cc/sa.xlarge.png";
import ndlogo from "./../../../assets/cc/nd.xlarge.png";
import EasyTrans from "../../bundel/EasyTrans";

const CC_BY_4_0 = "CC-BY-4.0";
const Attribution = "Attribution (CC BY 4.0)";

const CC_BY_NC_4_0 = "CC-BY-NC-4.0";
const AttributionNonCommercial = "Attribution-NonCommercial (CC BY-NC)";

const CC_BY_ND_4_0 = "CC-BY-ND-4.0";
const AttributionNoDerivatives = "Attribution-NoDerivatives (CC BY-ND)";

const CC_BY_NC_ND_4_0 = "CC-BY-NC-ND-4.0";
const AttributionNonCommercialNoDerivatives =
  "Attribution-NonCommercial-NoDerivatives (CC BY-NC-ND)";

const CC_BY_SA_4_0 = "CC-BY-SA-4.0";
const AttributionShareAlike = "Attribution-ShareAlike(CC BY-SA)";

const CC_BY_NC_SA_4_0 = "CC-BY-NC-SA-4.0";
const AttributionNonCommercialShareAlike =
  "Attribution-NonCommercial-ShareAlike (CC BY-NC-SA)";

const CCKitHelper = (props) => {
  const [shareAllowed, setShareAllowed] = useState(true);
  const [commercialAllowed, setCommercialAllowed] = useState(true);
  const [withSameCondition, setWithSameCondition] = useState(false);
  const [selectedLicenceCode, setSelectedLicenceCode] = useState("");
  const [selectedLicence, setSelectedLicence] = useState(
    "Attribution (CC BY 4.0)",
  );

  useEffect(() => {
    selectLicence(true, true, false);
    props.onSetLicence(selectedLicence);
  }, []);

  function selectLicence(
    shareAllowedParameter,
    commercialAllowedParameter,
    withSameConditionParameter,
  ) {
    let selectedLicenceCode = "";
    let selectedLicence = "";

    switch (true) {
      case shareAllowedParameter &&
        commercialAllowedParameter &&
        !withSameConditionParameter:
        selectedLicenceCode = CC_BY_4_0;
        selectedLicence = Attribution;
        break;
      case shareAllowedParameter &&
        !commercialAllowedParameter &&
        !withSameConditionParameter:
        selectedLicenceCode = CC_BY_NC_4_0;
        selectedLicence = AttributionNonCommercial;
        break;
      case !shareAllowedParameter &&
        commercialAllowedParameter &&
        !withSameConditionParameter:
        selectedLicenceCode = CC_BY_ND_4_0;
        selectedLicence = AttributionNoDerivatives;
        break;
      case !shareAllowedParameter &&
        !commercialAllowedParameter &&
        !withSameConditionParameter:
        selectedLicenceCode = CC_BY_NC_ND_4_0;
        selectedLicence = AttributionNonCommercialNoDerivatives;
        break;
      case withSameConditionParameter && commercialAllowedParameter:
        selectedLicenceCode = CC_BY_SA_4_0;
        selectedLicence = AttributionShareAlike;
        break;
      case withSameConditionParameter && !commercialAllowedParameter:
        selectedLicenceCode = CC_BY_NC_SA_4_0;
        selectedLicence = AttributionNonCommercialShareAlike;
        break;
      default:
        selectedLicenceCode = "";
        selectedLicence = "";
    }

    setSelectedLicenceCode(selectedLicenceCode);
    setSelectedLicence(selectedLicence);
    props.onSetLicence(selectedLicenceCode);
  }

  return (
    <>
      <div>
        <legend className="text-base font-medium text-gray-900">
          <EasyTrans>RegisterWork.License.LicenseKit.CCLicense</EasyTrans>
        </legend>
        <p className="text-sm text-gray-500">
          <EasyTrans>RegisterWork.License.LicenseKit.Description</EasyTrans>
        </p>
      </div>
      <div>
        <p className="text-sm text-gray-500">
          <EasyTrans>
            RegisterWork.License.LicenseKit.AllowSharedEdits
          </EasyTrans>
        </p>
      </div>
      <div className="mt-4 space-y-4">
        <div className="flex items-center">
          <input
            id="SharingArrangement"
            name="SharingArrangement"
            type="radio"
            defaultChecked={true}
            onChange={(event) => {
              if (event.target.checked) {
                setShareAllowed(true);
                setWithSameCondition(false);
                selectLicence(true, commercialAllowed, false);
              }
            }}
            className="focus:ring-fairblue-500 h-4 w-4 text-fairblue-500 border-gray-300"
          />
          <label
            htmlFor="SharingArrangement"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            <EasyTrans>RegisterWork.License.LicenseKit.Yes</EasyTrans>
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="SharingArrangement"
            name="SharingArrangement"
            type="radio"
            onChange={(event) => {
              if (event.target.checked) {
                setShareAllowed(false);
                setWithSameCondition(false);
                selectLicence(false, commercialAllowed, false);
              }
            }}
            className="focus:ring-fairblue-500 h-4 w-4 text-fairblue-500 border-gray-300"
          />
          <label
            htmlFor="SharingArrangementNo"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            <EasyTrans>RegisterWork.License.LicenseKit.No</EasyTrans>
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="SharingArrangement"
            name="SharingArrangement"
            type="radio"
            className="focus:ring-fairblue-500 h-4 w-4 text-fairblue-500 border-gray-300"
            onChange={(event) => {
              if (event.target.checked) {
                setShareAllowed(true);
                setWithSameCondition(true);
                selectLicence(true, commercialAllowed, true);
              }
            }}
          />
          <label
            htmlFor="SharingArrangement"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            <EasyTrans>
              RegisterWork.License.LicenseKit.AllowSharedSameConditions
            </EasyTrans>
          </label>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-500">
          <EasyTrans>
            RegisterWork.License.LicenseKit.AllowCommercialUse
          </EasyTrans>
        </p>
      </div>
      <div className="mt-4 space-y-4">
        <div className="flex items-center">
          <input
            id="CommercialArrangement"
            name="CommercialArrangement"
            type="radio"
            defaultChecked={true}
            onChange={() => {
              setCommercialAllowed(true);
              selectLicence(shareAllowed, true, withSameCondition);
            }}
            className="focus:ring-fairblue-500 h-4 w-4 text-fairblue-500 border-gray-300"
          />
          <label
            htmlFor="CommercialArrangement"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            <EasyTrans>RegisterWork.License.LicenseKit.Yes</EasyTrans>
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="CommercialArrangement"
            name="CommercialArrangement"
            type="radio"
            onChange={() => {
              setCommercialAllowed(false);
              selectLicence(shareAllowed, false, withSameCondition);
            }}
            className="focus:ring-fairblue-500 h-4 w-4 text-fairblue-500 border-gray-300"
          />
          <label
            htmlFor="CommercialArrangement"
            className="ml-3 block text-sm font-medium text-gray-700"
          >
            <EasyTrans>RegisterWork.License.LicenseKit.No</EasyTrans>
          </label>
        </div>

        <div className="flex space-x-0.5 flex-row items-center justify-evenly">
          <img className="h-20" src={cclogo} alt="cclogo" />
          <img className="h-20" src={bylogo} alt="bylogo" />
          {!commercialAllowed ? (
            <img className="h-20" src={nceulogo} alt="nceulogo" />
          ) : (
            <></>
          )}
          {!shareAllowed ? (
            <img className="h-20" src={ndlogo} alt="ndlogo" />
          ) : (
            <></>
          )}
          {withSameCondition ? (
            <img className="h-20" src={salogo} alt="salogo" />
          ) : (
            <></>
          )}
        </div>
        <div className="mt-1 text-center">{selectedLicence}</div>
      </div>
    </>
  );
};

export default CCKitHelper;
