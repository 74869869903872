import React, { useCallback, useState } from "react";

import ApiService from "../../services/ApiService";
import EasyTrans from "../bundel/EasyTrans";
import FormatService from "../../services/FormatService";
import Spinner from "../bundel/Spinner";
import { XIcon } from "@heroicons/react/outline";
import { useDropzone } from "react-dropzone";

const RegisterWorkFileUpload = (props) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [filesize, setFilesize] = useState("");
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        props.onSetFile(binaryStr);
      };
      reader.readAsArrayBuffer(file);
      props.onSetFilename(file.name);

      ApiService.uploadWork(
        file.name,
        file,
        props.onSetPresignedURL,
        props.onSetPrefix,
        setLoading,
      );

      setFilesize(FormatService.formatBytesSizeUnits(file.size));
      setFileUploaded(true);
      props.setFileError(false);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-fairblue-500">
              <EasyTrans>RegisterWork.Title.UploadWork</EasyTrans>
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              <EasyTrans>RegisterWork.Title.UploadWorkCaption</EasyTrans>
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <div
                  {...getRootProps()}
                  className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md ${
                    props.fileError ? "border-red-600" : "border-fairblue-500"
                  }`}
                >
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-fairblue-500 hover:text-fairdarkgreen focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-fairdarkgreen"
                      >
                        <span>
                          <EasyTrans>
                            RegisterWork.FileUpload.UploadFile
                          </EasyTrans>
                          <span className="text-red-600">*</span>
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          {...getInputProps()}
                          onClick={() => {
                            props.setFileError(false);
                          }}
                        />
                      </label>
                      <p className="pl-1">
                        <EasyTrans>
                          RegisterWork.FileUpload.UploadFileDragDrop
                        </EasyTrans>
                      </p>
                    </div>
                    <p className="text-xs text-gray-500">
                      <EasyTrans>RegisterWork.FileUpload.MaxFileSize</EasyTrans>
                    </p>
                  </div>
                </div>
                {props.fileError ? (
                  <span className="text-red-700">
                    <EasyTrans>RegisterWork.FileUpload.ChooseFile</EasyTrans>
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex justify-center">
                {loading ? (
                  <div className="flex justify-center column">
                    <Spinner className="center"></Spinner>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {fileUploaded && !loading ? (
                <div className="bg-fairdarkgreen">
                  <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center">
                        <p className="ml-3 font-medium text-white">
                          <span className="inline">{props.filename}</span>
                        </p>
                      </div>
                      <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                        <div className="flex items-center justify-between flex-wrap">
                          <div>
                            <p className="ml-3 font-medium text-white">
                              <span className="inline">{filesize}</span>
                            </p>
                          </div>
                          <button
                            type="button"
                            className="-mr-1 flex p-2 rounded-md hover:bg-fairdarkgreen focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                            onClick={() => {
                              //TODO presigned URL bereinigen
                              props.onSetPresignedURL("");
                              props.onSetPrefix("");
                              props.onSetFilename("");
                              props.onSetFile("");
                              setFileUploaded(false);
                            }}
                          >
                            <XIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default RegisterWorkFileUpload;
