import React, { useEffect, useState } from "react";

import ApiService from "../services/ApiService";
import EasyTrans from "../components/bundel/EasyTrans";
import Pagination from "../components/bundel/Pagination";
import Spinner from "../components/bundel/Spinner";
import UserWorkListElement from "../components/bundel/UserWorkListElement";
import WorkTypIcon from "../components/registerwork/WorkTypIcon";

const MyWorksPage = () => {
  const [workView, setWorkView] = useState(true);
  const [userWorkList, setUserWorkList] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState();
  const [pagination, setPagination] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await ApiService.getUserWorkList(page, itemsPerPage);
        console.log(result)
        setUserWorkList(result?.content);
        setTotalPages(result?.totalPages);
        setTotalElements(result?.totalElements);
        setPagination(result?.pageable);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, itemsPerPage]);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <div className="flex justify-center column p-10">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 my-2">
          {workView ? (
            userWorkList.length > 0 ? (
              userWorkList.map((userWork) => {
                return (
                  <UserWorkListElement
                    key={userWork.id}
                    userWork={userWork}
                    searchWork={true}
                    editable="true"
                  ></UserWorkListElement>
                );
              })
            ) : (
              <div className="flex items-center justify-center ">
                <p className="text-2xl text-gray-500">
                  <EasyTrans>NoElementsFound</EasyTrans>
                </p>
              </div>
            )
          ) : (
            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {userWorkList.map((work) => (
                <a key={work.id} href={work.href} className="group">
                  <div className="aspect-w-1 aspect-h-1 bg-fairbrightgreen rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                    <div className="flex justify-center items-center w-full h-full">
                      <WorkTypIcon
                        type="Document" //TODO Worktype zurückgeben work.type
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      ></WorkTypIcon>
                    </div>
                  </div>
                  <h3 className="text-sm text-gray-700">{work.licence}</h3>
                  <p className="mt-1 text-lg font-medium text-gray-900">
                    {work.title}
                  </p>
                </a>
              ))}
            </div>
          )}
          {totalElements && (
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalElements={totalElements}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MyWorksPage;
