import React from "react";

import SideMenu from "./SideMenu";

const Dashboard = () => {
  return (
    <div className="flex">
      <SideMenu></SideMenu>
      <section className="bg-white w-screen dark:bg-gray-900 p-5">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center"></div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
