import EasyTrans from "../../components/bundel/EasyTrans";
import React from "react";
import SideMenu from "./SideMenu";
import UserService from "../../services/UserService";

const Profil = () => {
  return (
    <div className="flex">
      <SideMenu></SideMenu>
      <div className="flex space-x-4"></div>
      <section className="bg-white w-screen dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <a href="/Profil">
              <div className="bg-green-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white">
                <span className="sr-only">
                  <EasyTrans>Navigation.SrUserMenuOpen</EasyTrans>
                </span>
              </div>
            </a>
            {/* TODO Keycloack Profil Picture
            <div className="mb-4 flex justify-center text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              <img
                className="h-32 w-32 rounded-full"
                src={stampicon}
                alt="stampicon"
              />
            </div>
            */}
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              {UserService.getDisplayName()}
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              {UserService.getUsername()}{" "}
            </p>
            <a
              href="https://id.fairkom.net/auth/realms/fairlogin/account"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fairblue-500 hover:bg-fairdarkgreen hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              target="_blank"
              rel="noreferrer"
            >
              Keycloak: Identity and access management
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profil;
