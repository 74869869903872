import axios from "axios";
import UserService from "./UserService";
import { REACT_APP_API } from "../config";

const _axios = axios.create({
  baseURL: REACT_APP_API,
});

const configure = () => {
  _axios.interceptors.request.use(async (config) => {
    if (!config.skipAuth) {
      await UserService.updateToken();
      config.headers.Authorization = `Bearer ${UserService.getToken()}`;
    }
    return config;
  });
};

const get = async (url, config = {}) => {
  return await _axios.get(url, config);
};

const post = async (url, data, config = {}) => {
  return await _axios.post(url, data, config);
};

const put = async (url, data, config = {}) => {
  return await _axios.put(url, data, config);
};

const HttpClient = {
  configure,
  get,
  post,
  put,
};

export default HttpClient;
