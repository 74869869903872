import EasyTrans from "../bundel/EasyTrans";
import InputField from "../bundel/InputField";
import React from "react";
import moment from "moment";

const RegisterCheck = ({
  type,
  title,
  description,
  tags,
  language,
  creationDate,
  creator,
  contributors,
  publisher,
  identifier,
  filename,
  visibility,
  storageOptions,
  licence,
  onGoBack,
  onSendRegisterFinally,
}) => {
  return (
    <div className=" max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                <EasyTrans>RegisterWork.RegisterCheck.Title</EasyTrans>
              </h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <fieldset>
                    <div>
                      <p className="text-sm text-gray-500"></p>
                    </div>
                    <div>
                      <div className="flex flex-wrap flex-col">
                        <div className="w-full">
                          <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                            <EasyTrans>
                              RegisterWork.Title.WorkInformation
                            </EasyTrans>
                          </h3>
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.FileName
                              </EasyTrans>
                            }
                            type="text"
                            name="licenseDalicc"
                            defaultValue={filename}
                            disabled="false"
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                RegisterWork.Element.CreationType
                              </EasyTrans>
                            }
                            type="text"
                            name="type"
                            defaultValue={type}
                            disabled="false"
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.Name
                              </EasyTrans>
                            }
                            type="text"
                            name="title"
                            defaultValue={title}
                            disabled="false"
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.WorkDescription
                              </EasyTrans>
                            }
                            type="text"
                            name="description"
                            defaultValue={description}
                            disabled="false"
                          />
                          {tags.length <= 0 && (
                            <InputField
                              label="Tags"
                              type="text"
                              name="tags"
                              searchWork="true"
                              defaultValue={tags.join(", ")}
                              disabled="false"
                            />
                          )}
                          <InputField
                            label={<EasyTrans>Language</EasyTrans>}
                            type="text"
                            name="language"
                            searchWork="true"
                            defaultValue={language}
                            disabled="false"
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.CreationDate
                              </EasyTrans>
                            }
                            type="text"
                            name="creationDate"
                            defaultValue={moment(creationDate)
                              .utc()
                              .format("YYYY-MM-DD")}
                            disabled="false"
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.Participant
                              </EasyTrans>
                            }
                            type="text"
                            name="creator"
                            defaultValue={creator}
                            disabled="false"
                          />
                          {contributors.map((contributor, index) => {
                            if (index !== 0) {
                              return (
                                <InputField
                                  key={index}
                                  label={
                                    <EasyTrans>
                                      MyWorks.WorkListElement.Authors
                                    </EasyTrans>
                                  }
                                  type="text"
                                  name="creator"
                                  defaultValue={contributor.email}
                                  disabled={true}
                                />
                              );
                            }
                          })}
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.Publisher
                              </EasyTrans>
                            }
                            type="text"
                            name="publisher"
                            disabled="false"
                            searchWork="true"
                            defaultValue={publisher}
                          />
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.Identifier
                              </EasyTrans>
                            }
                            type="text"
                            name="identifier"
                            searchWork="true"
                            defaultValue={identifier}
                            disabled="false"
                          />
                          <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                            <EasyTrans>
                              RegisterWork.RegisterCheck.Licence
                            </EasyTrans>
                          </h3>
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.License
                              </EasyTrans>
                            }
                            type="text"
                            name="licenseIdentifier"
                            defaultValue={licence}
                            disabled="false"
                          />
                          <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                            <EasyTrans>
                              RegisterWork.RegisterCheck.SaveOptions
                            </EasyTrans>
                          </h3>

                          <div className="px-6">
                            <div className="flex items-center">
                              <input
                                id="filesystem-save"
                                name="filesystem-save"
                                type="checkbox"
                                checked={true}
                                className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                              />
                              <label
                                htmlFor="filesystem-save"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                <EasyTrans>
                                  RegisterWork.SecurityAndStorage.Description
                                </EasyTrans>
                              </label>
                            </div>

                            {storageOptions.includes(
                              "FAIRREGISTER_FILE_SYSTEM",
                            ) && (
                              <div className="flex items-center">
                                <input
                                  id="filesystem-save"
                                  name="filesystem-save"
                                  type="checkbox"
                                  checked={true}
                                  className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                                />
                                <label
                                  htmlFor="filesystem-save"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  <EasyTrans>
                                    RegisterWork.SecurityAndStorage.SaveInFairregister
                                  </EasyTrans>
                                </label>
                              </div>
                            )}

                            {storageOptions.includes("IPFS") && (
                              <div className="flex items-center">
                                <input
                                  id="filesystem-save"
                                  name="filesystem-save"
                                  type="checkbox"
                                  checked={true}
                                  className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                                />
                                <label
                                  htmlFor="filesystem-save"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  <EasyTrans>
                                    RegisterWork.SecurityAndStorage.SaveInIPFS
                                  </EasyTrans>
                                </label>
                              </div>
                            )}
                            {storageOptions.includes("OWN_STORAGE") && (
                              <div className="flex items-center">
                                <input
                                  id="filesystem-save"
                                  name="filesystem-save"
                                  type="checkbox"
                                  checked={true}
                                  className="focus:ring-green-500 h-4 w-4 text-red-600 border-gray-300"
                                />
                                <label
                                  htmlFor="filesystem-save"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  <EasyTrans>
                                    RegisterWork.SecurityAndStorage.SaveSelf
                                  </EasyTrans>
                                </label>
                              </div>
                            )}
                            <div className="flex items-center">
                              <input
                                id="filesystem-save"
                                name="filesystem-save"
                                type="checkbox"
                                checked={visibility}
                                className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                              />
                              <label
                                htmlFor="filesystem-save"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                <EasyTrans>
                                  RegisterWork.SecurityAndStorage.VisibilityTitle
                                </EasyTrans>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex items-center justify-between">
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fairblue-500 hover:bg-fairdarkgreen hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-4"
                    onClick={onGoBack}
                  >
                    Zurück
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fairblue-500 hover:bg-fairdarkgreen hover:text-black  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-4"
                    onClick={onSendRegisterFinally}
                  >
                    <EasyTrans>RegisterWork.Register</EasyTrans>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default RegisterCheck;
