import React from "react";
import HelpIcon from "./HelpIcon";
import EasyTrans from "../bundel/EasyTrans";

const RegisterWorkSave = (props) => {
  return (
    <div className=" max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                <EasyTrans>
                  RegisterWork.Title.SecurityAndStorageOptions
                </EasyTrans>
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                <EasyTrans>
                  RegisterWork.Title.SecurityAndStorageOptionsCaption
                </EasyTrans>
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <fieldset>
                      <div>
                        <legend className="text-base font-medium text-gray-900">
                          <EasyTrans>
                            RegisterWork.SecurityAndStorage.Metadata
                          </EasyTrans>
                        </legend>
                        <p className="text-sm text-gray-500"></p>
                      </div>
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <input
                            id="default-save"
                            name="default-save"
                            type="checkbox"
                            checked={true}
                            defaultChecked={true}
                            className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                          />
                          <label
                            htmlFor="default-save-db"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            <EasyTrans>
                              RegisterWork.SecurityAndStorage.Description
                            </EasyTrans>
                          </label>
                          <HelpIcon
                            Title="RegisterWork.SecurityAndStorage.Description"
                            Message=""
                            TransKey="RegisterWork.SecurityAndStorage.HelpSaveDescription"
                          ></HelpIcon>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <fieldset>
                      <legend className="text-base font-medium text-gray-900">
                        <EasyTrans>
                          RegisterWork.SecurityAndStorage.TheCreation
                        </EasyTrans>
                        <span className="text-red-600">*</span>
                      </legend>
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <input
                            id="default-save"
                            name="default-save"
                            type="checkbox"
                            checked={props.saveFileSystem}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              props.onSetSaveFileSystem(checked);
                              if (!props.saveIpfsSystem)
                                props.onSetSelfSave(!checked);
                            }}
                            className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                          />
                          <label
                            htmlFor="default-save-db"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            <EasyTrans>
                              RegisterWork.SecurityAndStorage.SaveInFairregister
                            </EasyTrans>
                          </label>
                          <HelpIcon
                            Title="RegisterWork.SecurityAndStorage.SaveInFairregister"
                            Message="Es bedeutet, dass die Sicherung im fairregister Dateisystem erfolgt"
                            TransKey="RegisterWork.SecurityAndStorage.HelpSaveInFairregister"
                          ></HelpIcon>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="filesystem-save"
                            name="filesystem-save"
                            type="checkbox"
                            checked={props.saveIpfsSystem}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              props.onSetSaveIpfsSystem(checked);
                              if (!props.saveFileSystem)
                                props.onSetSelfSave(!checked);
                            }}
                            className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                          />
                          <label
                            htmlFor="filesystem-save"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            <EasyTrans>
                              RegisterWork.SecurityAndStorage.SaveInIPFS
                            </EasyTrans>
                          </label>
                          <HelpIcon
                            Title="RegisterWork.SecurityAndStorage.SaveInIPFS"
                            Message="Es bedeutet, dass die Sicherung in IPFS erfolgt"
                            TransKey="RegisterWork.SecurityAndStorage.HelpSaveInIPFS"
                          ></HelpIcon>
                        </div>

                        <fieldset>
                          <div className="mt-4 space-y-4"></div>
                        </fieldset>
                        <div className="flex items-center">
                          <input
                            id="filesystem-save"
                            name="filesystem-save"
                            type="checkbox"
                            checked={props.saveSelfSave}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              props.onSetSelfSave(checked);
                              props.onSetSaveFileSystem(!checked);
                              props.onSetSaveIpfsSystem(!checked);
                            }}
                            className="focus:ring-green-500 h-4 w-4 text-red-600 border-gray-300"
                          />
                          <label
                            htmlFor="filesystem-save"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            <EasyTrans>
                              RegisterWork.SecurityAndStorage.SaveSelf
                            </EasyTrans>
                          </label>
                          <HelpIcon
                            Title="RegisterWork.SecurityAndStorage.SaveSelf"
                            Message="Es bedeutet man ist für die Sicherung der Datei selbst verantwortlich"
                            TransKey="RegisterWork.SecurityAndStorage.HelpSaveSelf"
                          ></HelpIcon>
                        </div>
                      </div>
                      {props.selfSave ? (
                        <span className="text-red-700">
                          <EasyTrans>
                            RegisterWork.SecurityAndStorage.SaveSelfWarning
                          </EasyTrans>
                        </span>
                      ) : (
                        <></>
                      )}
                    </fieldset>
                  </div>
                </div>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <fieldset>
                      <div>
                        <legend className="text-base font-medium text-gray-900">
                          <EasyTrans>
                            RegisterWork.SecurityAndStorage.VisibilityTitle
                          </EasyTrans>
                        </legend>
                        <p className="text-sm text-gray-500"></p>
                      </div>
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <input
                            id="private-work-save"
                            name="private-work-save"
                            type="checkbox"
                            onChange={(event) => {
                              const checked = event.target.checked;
                              props.onSetVisibility(!checked);
                            }}
                            checked={!props.visibility}
                            className="focus:ring-green-500 h-4 w-4 text-fairblue-500 border-gray-300"
                          />
                          <label
                            htmlFor="private-work-save"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            <EasyTrans>
                              RegisterWork.SecurityAndStorage.NoVisibility
                            </EasyTrans>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default RegisterWorkSave;
