import HelpIcon from "./HelpIcon";
import WorkTypIcon from "./WorkTypIcon";
import React from "react";

const WorktypButton = (props) => {
  return (
    <div>
      <input
        type="radio"
        name="Worktype"
        id={props.type}
        className="sr-only peer"
        value={props.type}
        onClick={() => {
          props.onSetType(props.type);
          props.setTypeError(false);
        }}
      />
      <label
        htmlFor={props.type}
        className={`group cursor-pointer flex flex-col w-full mt-1 border peer-checked:bg-fairblue-500 fill-black hover:fill-white peer-checked:fill-white hover:bg-fairblue-500 hover:text-white peer-checked:text-white peer-checked:outline-none rounded-md shadow-sm  peer-checked:ring-2 peer-checked:ring-offset-2 peer-checked:ring-green-500 ${
          props.typeError ? "border-red-700 bg-red-50" : "border-gray-300 "
        }`}
      >
        <h3 className="font-bold text-center">{props.type}</h3>
        <div className="flex items-center justify-center">
          <WorkTypIcon type={props.type}></WorkTypIcon>
        </div>
        <div className="flex items-center justify-center">
          <HelpIcon
            Title={props.type}
            className="fill-inherit hover:!fill-gray-400"
            Message={props.helpMessage}
          ></HelpIcon>
        </div>
      </label>
    </div>
  );
};

export default WorktypButton;
