import EasyTrans from "../bundel/EasyTrans";
import HelpIcon from "./HelpIcon";
import React from "react";

const WorkCreatorForm = (props) => {
  var creator = {
    firstName: props.currentContributor.FirstName,
    lastName: props.currentContributor.LastName,
    email: props.currentContributor.EMail,
    pseudonym: props.currentContributor.Pseudonym,
    percentage: props.currentContributor.Percentage,
    url: props.currentContributor.url,
  };

  props.contributorList.map((x, index) => {
    if (index === props.index) {
      creator = x;
    }
  });

  function setNewVal(value, newValue) {
    switch (value) {
      case "firstname":
        props.currentContributor.firstName = newValue;
        break;
      case "lastname":
        props.currentContributor.lastName = newValue;
        break;
      case "email":
        props.currentContributor.email = newValue;
        break;
      case "pseudonym":
        props.currentContributor.pseudonym = newValue;
        break;
      case "url":
        props.currentContributor.url = newValue;
        break;
      case "percent":
        props.currentContributor.percentage = newValue;
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="shadow mb-5 overflow-hidden sm:rounded-md">
        <div
          className={`px-4 bg-white space-y-6 sm:p-6 ${
            props.creatorError ? "border-2 border-red-700 bg-red-50" : ""
          }`}
        >
          <div className="px-2">
            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                <EasyTrans>RegisterWork.WorkCreator.EMail</EasyTrans>
                <span className="text-red-600">*</span>
              </label>
              {props.noHelpNeeded ? (
                <></>
              ) : (
                <HelpIcon
                  Title="RegisterWork.WorkCreator.EMail"
                  Message=""
                  TransKey="RegisterWork.WorkCreator.HelpEMail"
                ></HelpIcon>
              )}
            </div>
            <input
              id="email"
              type="email"
              name="email"
              defaultValue={props.currentContributor.email}
              onChange={(event) => setNewVal("email", event.target.value)}
              onFocus={(event) => props.setEmailError(false)}
              className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                props.emailError ? "border-red-700 bg-red-50" : ""
              }`}
              disabled={props.edit}
            />
          </div>
          <div className="contents md:flex">
            <div className="px-2 basis-1/2">
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  <EasyTrans>RegisterWork.WorkCreator.FirstName</EasyTrans>
                  <span className="text-red-600">*</span>
                </label>
                {props.noHelpNeeded ? (
                  <></>
                ) : (
                  <HelpIcon
                    Title="RegisterWork.WorkCreator.FirstName"
                    Message=""
                    TransKey="RegisterWork.WorkCreator.HelpFirstName"
                  ></HelpIcon>
                )}
              </div>
              <input
                id="firstname"
                type="text"
                name="firstname"
                defaultValue={props.currentContributor.firstName}
                onChange={(event) => setNewVal("firstname", event.target.value)}
                onFocus={(event) => props.setFirstnameError(false)}
                className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                  props.firstnameError ? "border-red-700 bg-red-50" : ""
                }`}
                disabled={props.edit}
              />
            </div>
            <div className="px-2 basis-1/2">
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  <EasyTrans>RegisterWork.WorkCreator.LastName</EasyTrans>
                  <span className="text-red-600">*</span>
                </label>
                {props.noHelpNeeded ? (
                  <></>
                ) : (
                  <HelpIcon
                    Title="RegisterWork.WorkCreator.LastName"
                    Message=""
                    TransKey="RegisterWork.WorkCreator.HelpLastName"
                  ></HelpIcon>
                )}
              </div>
              <input
                id="lastname"
                type="text"
                name="lastname"
                defaultValue={props.currentContributor.lastName}
                onChange={(event) => setNewVal("lastname", event.target.value)}
                onFocus={(event) => props.setLastnameError(false)}
                className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                  props.lastnameError ? "border-red-700 bg-red-50" : ""
                }`}
                disabled={props.edit}
              />
            </div>
          </div>
          <div className="contents md:flex">
            <div className="px-2 basis-1/2">
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                <label
                  htmlFor="url"
                  className="block text-sm font-medium text-gray-700"
                >
                  <EasyTrans>RegisterWork.WorkCreator.PercentTitle</EasyTrans>
                  <span className="text-red-600">*</span>
                </label>
                {props.noHelpNeeded ? (
                  <></>
                ) : (
                  <HelpIcon
                    Title="RegisterWork.WorkCreator.PercentTitle"
                    Message="Wie viel Prozent hat der Mitwirkende sich beteiligt am Werk?"
                    TransKey="RegisterWork.WorkCreator.HelpPercent"
                  ></HelpIcon>
                )}
              </div>
              <input
                id="percent"
                type="text"
                name="percent"
                defaultValue={props.currentContributor.percentage}
                onChange={(event) => setNewVal("percent", event.target.value)}
                onFocus={(event) => props.setPercentageError(false)}
                className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                  props.percentageError ? "border-red-700 bg-red-50" : ""
                }`}
              />
            </div>
            <div className="px-2 basis-1/2">
              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                <label
                  htmlFor="pseudonym"
                  className="block text-sm font-medium text-gray-700"
                >
                  <EasyTrans>RegisterWork.WorkCreator.Pseudonym</EasyTrans>
                  <span className="text-red-600">*</span>
                </label>
                {props.noHelpNeeded ? (
                  <></>
                ) : (
                  <HelpIcon
                    Title="RegisterWork.WorkCreator.Pseudonym"
                    Message="Wenn ein Pseudonym eingetragen wird, wird dieses für die Veröffentlichung verwendet und nicht der Vor- und Nachname"
                    TransKey="RegisterWork.WorkCreator.HelpPseudonym"
                  ></HelpIcon>
                )}
              </div>
              <input
                id="pseudonym"
                type="text"
                name="pseudonym"
                defaultValue={props.currentContributor.pseudonym}
                onChange={(event) => setNewVal("pseudonym", event.target.value)}
                onFocus={(event) => props.setPseudonymError(false)}
                className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                  props.pseudonymError ? "border-red-700 bg-red-50" : ""
                }`}
                disabled={props.edit}
              />
            </div>
          </div>
          <div className="px-2">
            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
              <label
                htmlFor="url"
                className="block text-sm font-medium text-gray-700"
              >
                <EasyTrans>RegisterWork.WorkCreator.URL</EasyTrans>
              </label>
              {props.noHelpNeeded ? (
                <></>
              ) : (
                <HelpIcon
                  Title="RegisterWork.WorkCreator.URL"
                  Message="URL (Uniform Resource Locator)"
                  TransKey="RegisterWork.WorkCreator.HelpURL"
                ></HelpIcon>
              )}
            </div>
            <input
              id="url"
              type="text"
              name="url"
              defaultValue={creator.url}
              onChange={(event) => setNewVal("url", event.target.value)}
              className="mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              disabled={props.edit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkCreatorForm;
