import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowCircleDownIcon as DownloadIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import ApiService from "../../services/ApiService";
import UserService from "../../services/UserService";

const PublicWorkCard = ({ work }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const copyToClipboard = (grid) => {
    const url = `${window.location.host}/works/${grid}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const downloadPublicWork = (grid) => {
    ApiService.downloadPublicWork(grid);
  };

  return (
    <div className="relative max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-4">
      <div className="absolute top-4 right-4 flex space-x-2">
        {UserService.isLoggedIn() && (
          <button
            className="text-gray-500 hover:text-fairblue-500"
            onClick={() => downloadPublicWork(work.grid)}
          >
            <DownloadIcon className="w-6 h-6" />
          </button>
        )}
        <button
          className="text-gray-500 hover:text-fairblue-500"
          onClick={() => copyToClipboard(work.grid)}
        >
          <ShareIcon className="w-6 h-6" />
        </button>
        {tooltipVisible && (
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 p-2 bg-black text-white text-xs rounded-md">
            Copied
          </div>
        )}
      </div>
      <div className="p-8">
        <div className="uppercase tracking-wide text-sm text-fairblue-500 font-semibold">
          {work.type}
        </div>
        <div className="block mt-1 text-lg leading-tight font-medium text-black">
          {work.title}
        </div>
        <p className="mt-2 text-gray-500">{work.description}</p>
      </div>
      <div className="px-8 py-4 border-t border-gray-200 grid grid-cols-2 gap-4">
        <span className="text-gray-700 font-medium">
          Author:{" "}
          <a
            href={work.creator.author.url}
            className="text-fairblue-500 hover:text-fairblue-700 hover:underline"
          >
            {work.creator.author.pseudonym}
          </a>
        </span>
        <span className="text-gray-700 font-medium">
          Created: {new Date(work.creationDate).toLocaleDateString()}
        </span>
        <span className="text-gray-700 font-medium">
          Registered: {new Date(work.registrationDate).toLocaleDateString()}
        </span>
        <span className="text-gray-700 font-medium">
          License: {work.license.identifier}
        </span>
      </div>
      <div className="px-8 py-4">
        {work.tags.map((tag, index) => (
          <span
            key={index}
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

PublicWorkCard.propTypes = {
  work: PropTypes.object.isRequired,
};

export default PublicWorkCard;
