import React, { useEffect, useState } from "react";

import eight from "./../assets/gallery/8.png";
import five from "./../assets/gallery/5.png";
import four from "./../assets/gallery/4.png";
import one from "./../assets/gallery/1.png";
import seven from "./../assets/gallery/7.png";
import six from "./../assets/gallery/6.png";
import three from "./../assets/gallery/3.png";
import two from "./../assets/gallery/2.png";
import workingdude from "./../assets/workingdude.png";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const images = [workingdude, one, two, three, four, five, six, seven, eight];

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0);
      setTimeout(() => {
        setCurrentIndex((currentIndex + 1) % images.length);
        setOpacity(1);
      }, 500);
    }, 10000);
    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <img
      className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full transition-opacity duration-500"
      src={images[currentIndex]}
      alt="workingdude"
      style={{ opacity }}
    />
  );
};

export default Gallery;
