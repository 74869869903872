import EasyTrans from "./EasyTrans";
import React from "react";

const WorkCreatorFormView = (props) => {
  var creator = {
    firstName: props?.creator?.author.firstName,
    lastName: props?.creator?.author.lastName,
    email: props?.creator?.author.email,
    pseudonym: props?.creator?.author.pseudonym,
    percentage: props?.creator?.percentage,
    url: props?.creator?.author.url,
  };

  function setNewVal(value, newValue) {
    switch (value) {
      case "firstname":
        creator.firstName = newValue;
        break;
      case "lastname":
        creator.lastName = newValue;
        break;
      case "email":
        creator.email = newValue;
        break;
      case "pseudonym":
        creator.pseudonym = newValue;
        break;
      case "url":
        creator.url = newValue;
        break;
      case "percent":
        creator.percentage = newValue;
        break;

      default:
        break;
    }
    props.onCreatorChanged(creator, props.id);
  }

  return (
    <>
      {!props.publicWork && (
        <div className="px-2">
          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              <EasyTrans>RegisterWork.WorkCreator.EMail</EasyTrans>
            </label>
          </div>
          <input
            id="email"
            type="email"
            name="email"
            defaultValue={creator.email}
            onChange={(event) => setNewVal("email", event.target.value)}
            className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            disabled={props.edit}
          />
        </div>
      )}
      {!props.publicWork && (
        <div className="contents md:flex">
          <div className="px-2 basis-1/2">
            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-700"
              >
                <EasyTrans>RegisterWork.WorkCreator.FirstName</EasyTrans>
              </label>
            </div>
            <input
              id="firstname"
              type="text"
              name="firstname"
              defaultValue={creator.firstName}
              onChange={(event) => setNewVal("firstname", event.target.value)}
              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              disabled={props.edit}
            />
          </div>
          <div className="px-2 basis-1/2">
            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700"
              >
                <EasyTrans>RegisterWork.WorkCreator.LastName</EasyTrans>
              </label>
            </div>
            <input
              id="lastname"
              type="text"
              name="lastname"
              defaultValue={creator.lastName}
              onChange={(event) => setNewVal("lastname", event.target.value)}
              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              disabled={props.edit}
            />
          </div>
        </div>
      )}

      <div className="contents md:flex">
        <div className="px-2  basis-1/2">
          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              <EasyTrans>RegisterWork.WorkCreator.PercentTitle</EasyTrans>
            </label>
          </div>
          <input
            id="percent"
            type="text"
            name="percent"
            defaultValue={creator.percentage}
            onChange={(event) => setNewVal("percent", event.target.value)}
            className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            disabled={props.edit}
          />
        </div>
        <div className={"px-2 basis-1/2"}>
          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
            <label
              htmlFor="pseudonym"
              className="block text-sm font-medium text-gray-700"
            >
              <EasyTrans>RegisterWork.WorkCreator.Pseudonym</EasyTrans>
            </label>
          </div>
          <input
            id="pseudonym"
            type="text"
            name="pseudonym"
            defaultValue={creator.pseudonym}
            onChange={(event) => setNewVal("pseudonym", event.target.value)}
            className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            disabled={props.edit}
          />
        </div>
      </div>
      <div className="px-2">
        <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
          <label
            htmlFor="url"
            className="block text-sm font-medium text-gray-700"
          >
            <EasyTrans>RegisterWork.WorkCreator.URL</EasyTrans>
          </label>
        </div>
        <input
          id="url"
          type="text"
          name="url"
          defaultValue={creator.url}
          onChange={(event) => setNewVal("url", event.target.value)}
          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          disabled={true}
        />
      </div>
    </>
  );
};

export default WorkCreatorFormView;
