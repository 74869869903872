import Keycloak from "keycloak-js";
import { KEYCLOAK_CLIENT_ID } from "../config";

const _kc = new Keycloak({
  url: "https://id.fairkom.net/auth",
  clientId: KEYCLOAK_CLIENT_ID,
  realm: "fairlogin",
  "ssl-requiered": "external",
  "public-client": true,
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user not authenticated");
      }
      onAuthenticatedCallback();
    })
    .catch((error) => {
      console.error(error);
    });
};

const doLogin = _kc.login;

const doRegister = _kc.register;

const doLogout = _kc.logout;

const getToken = () => {
  return _kc.token;
};

const isLoggedIn = () => {
  //console.log(_kc.token)
  //console.log(!!_kc.token)
  //localStorage.setItem('token', _kc.token)
  return !!_kc.token;
};

const updateToken = () =>
  _kc.updateToken(5).catch(doLogin);

const getUserId = () => _kc.tokenParsed?.sub;
const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;
const getDisplayName = () => _kc.tokenParsed?.name;
const getEMail = () => _kc.tokenParsed?.email;
const getRoleAccessList = () => _kc.getUserRoles();

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  getUserId,
  doLogin,
  doRegister,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFirstName,
  getLastName,
  getDisplayName,
  getEMail,
  getRoleAccessList,
  hasRole,
};

export default UserService;
