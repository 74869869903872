import React from "react";
import SideMenu from "./SideMenu";
import EasyTrans from "../../components/bundel/EasyTrans";

const Certificates = () => {
  return (
    <div className="flex">
      <SideMenu></SideMenu>
      <section className="bg-white w-screen dark:bg-gray-900">
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  <EasyTrans>Settings.Certificates.Name</EasyTrans>
                </th>
                <th scope="col" className="py-3 px-6">
                  <EasyTrans>Settings.Certificates.Date</EasyTrans>
                </th>
                <th scope="col" className="py-3 px-6">
                  <EasyTrans>Settings.Certificates.Worktype</EasyTrans>
                </th>
                <th scope="col" className="py-3 px-6">
                  <EasyTrans>Settings.Certificates.Download</EasyTrans>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  Licence one
                </th>
                <td className="py-4 px-6">Licence two</td>
                <td className="py-4 px-6">Licence three</td>
                <td className="py-4 px-6">Licence four</td>
              </tr>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  Microsoft Surface Pro
                </th>
                <td className="py-4 px-6">White</td>
                <td className="py-4 px-6">Laptop PC</td>
                <td className="py-4 px-6">$1999</td>
              </tr>
              <tr className="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  Magic Mouse 2
                </th>
                <td className="py-4 px-6">Black</td>
                <td className="py-4 px-6">Accessories</td>
                <td className="py-4 px-6">$99</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default Certificates;
