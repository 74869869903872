import HttpClient from "./HttpClient";
import UserService from "./UserService";
import { saveAs } from "file-saver";
import { REACT_APP_API } from "../config";

const getUserWorkList = async (page, size) => {
  const keycloakUserId = UserService.getUserId();
  const url = `/users/${keycloakUserId}/works?page=${page}&size=${size}`;

  try {
    const result = await HttpClient.get(url, {
      headers: { "Content-Type": "application/json" },
    });

    return result.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const getPublicWorkList = async (
  title,
  worktype,
  licence,
  tags,
  page,
  size,
  sort,
) => {
  let url = "/works/public/filter?";
  if (worktype) {
    url += `type=${worktype}&`;
  }
  if (title) {
    url += `title=${title}&`;
  }
  if (licence) {
    url += `licence=${licence}&`;
  }
  if (tags) {
    url += `tags=${tags}&`;
  }
  if (page) {
    url += `page=${page}&`;
  }
  if (size) {
    url += `size=${size}&`;
  }

  if (sort) {
    url += `sort=${sort}&`;
  }
  // Remove trailing '&' if there are any parameters
  url = url.endsWith("&") ? url.slice(0, -1) : url;

  try {
    const result = await HttpClient.get(url, {
      skipAuth: true,
      headers: { "Content-Type": "application/json" },
    });

    return result.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const getLicenceList = async () => {
  const url = `https://raw.githubusercontent.com/spdx/license-list-data/master/json/licenses.json`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const licenses = await response.json();
    return licenses;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const downloadCertificate = async (workId) => {
  try {
    const response = await HttpClient.get(`/works/${workId}/certificate`, {
      responseType: "blob",
    });
    saveAs(response.data, `${workId}.pdf`);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const downloadWork = async (workId, filename) => {
  try {
    const response = await HttpClient.get(`/works/${workId}/download`, {
      responseType: "blob",
    });

    saveAs(response.data, `${filename}`);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const downloadPublicWork = async (grid) => {
  window.open(`${REACT_APP_API}/works/public/${grid}/download`, "_blank");
};

const getPublicWork = async (grid) => {
  try {
    const response = await HttpClient.get(`/works/public/${grid}`, {
      skipAuth: true,
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const uploadWork = async (
  filename,
  file,
  onSetPresignedURL,
  onSetPrefix,
  setLoading,
) => {
  try {
    const keycloakUserId = UserService.getUserId();
    const response = await HttpClient.get(
      `/works/upload/presigned-url?filename=${filename}&userId=${keycloakUserId}`,
    );
    const presignedUploadUrl = response.data?.url;
    const prefix = response.data?.prefix;

    onSetPresignedURL(presignedUploadUrl);
    onSetPrefix(prefix);

    await uploadAsset(presignedUploadUrl, file, setLoading);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const uploadAsset = async (url, file, setLoading) => {
  try {
    await HttpClient.put(url, file, { skipAuth: true });
    setLoading(false);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const registerWork = async (raw) => {
  try {
    const response = await HttpClient.post(
      "/works/register",
      JSON.stringify(raw),
      { headers: { "Content-Type": "application/json" } },
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const ApiService = {
  registerWork,
  downloadCertificate,
  downloadWork,
  downloadPublicWork,
  getPublicWorkList,
  getPublicWork,
  getUserWorkList,
  getLicenceList,
  uploadWork,
};

export default ApiService;
