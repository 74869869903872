import React from "react";

const PageLink = (props) => {
  return (
    <a
      key={props.index}
      aria-current="page"
      className={`cursor-pointer ${
        props.currentPage === props.index
          ? "bg-indigo-50 border-indigo-500 z-10"
          : "bg-white border-gray-300"
      } text-fairblue-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
      onClick={() => props.setPage(props.index)}
    >
      {props.index + 1}
    </a>
  );
};

export default PageLink;
