import React, { useState, useEffect } from "react";
import EasyTrans from "../../bundel/EasyTrans";

const AllLicences = (props) => {
  const [licenceLink, setLicenceLink] = useState("");

  useEffect(() => {
    props.onSetLicence(props.spdxIdentifer);
    SetLicenceLinkURL(props.spdxIdentifer);
  }, []);

  function SetLicenceLinkURL(spdxIdentifer) {
    props.licences.map((licence) => {
      if (licence.licenseId == spdxIdentifer) {
        setLicenceLink(licence.detailsUrl);
      }
    });
  }

  return (
    <>
      <select
        name="select-licence"
        id="select-licence"
        defaultValue={props.spdxIdentifer}
        onClick={(event) => {
          props.setLicenceError(false);
        }}
        onChange={(event) => {
          props.setLicenceError(false);
          const spdxID = event.target.value;
          if (spdxID == 0) {
            props.onSetLicence("");
            setLicenceLink("");
          } else {
            SetLicenceLinkURL(spdxID);
            props.onSetLicence(spdxID);
          }
        }}
        className={`mt-1 block w-full py-2 px-3 border bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm ${
          props.licenceError
            ? "border-2 border-red-700 bg-red-50"
            : " border-gray-300 "
        }`}
      >
        <option value="Full Copyright">
          <EasyTrans>RegisterWork.License.CommonRightsReserved</EasyTrans>
        </option>
        <option value="No Assertion">
          <EasyTrans>RegisterWork.License.NoAssertion</EasyTrans>
        </option>
        {props.licences.map((licence) => {
          return (
            <option key={licence.referenceNumber} value={licence.licenseId}>
              {licence.name}
            </option>
          );
        })}
      </select>

      {licenceLink ? (
        <a className="text-sm text-fairblue-500" href={licenceLink}>
          <EasyTrans>RegisterWork.License.FurtherInformation</EasyTrans>:{" "}
          {licenceLink}
        </a>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default AllLicences;
