import React, { useState } from "react";

import AddAndDeleteButton from "../bundel/AddAndDeleteButton";
import { ArrowDownIcon } from "@heroicons/react/outline";
import Collapsible from "react-collapsible";
import EasyTrans from "../bundel/EasyTrans";
import HelpIcon from "./HelpIcon";
import ISO6391 from "iso-639-1"; //https://www.npmjs.com/package/iso-639-1
import WorkCreatorForm from "./WorkCreatorForm";
import WorktypButton from "./WorktypButton";
import { useTranslation } from "react-i18next";

const RegisterWorkInformation = (props) => {
  //#region States
  const { t } = useTranslation();
  const [tagsList, setTagsList] = useState([]);
  const [newTags, setNewTags] = useState([]);
  let inputTagsID = 0;
  //#endregion

  //#region Methods
  //#region Add + Delete Elements
  //#region Tags
  function onAddTag() {
    let newTagsList = tagsList;

    let tagObject = {
      id: ++inputTagsID,
      value: "",
    };

    newTagsList.push(tagObject);
    setNewTags(newTagsList);
    setTagsList([...newTagsList]);
    props.onSetTags(tagsList);
  }

  function onDeleteTag() {
    tagsList.pop();
    setTagsList([...tagsList]);
  }
  //#endregion
  //#region cretor
  function onAddAuthor() {
    props.onSetContributorList(
      props.contributorList.concat({
        firstName: "",
        lastName: "",
        email: "",
        pseudonym: "",
        url: "",
        percentage: 0,
      }),
    );
  }

  function onDeleteAuthor() {
    props.contributorList.pop();
    props.onSetContributorList([...props.contributorList]);
  }

  //#endregion
  //#endregion
  //#endregion

  //#region View
  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                <EasyTrans>RegisterWork.Title.WorkInformation</EasyTrans>
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                <EasyTrans>RegisterWork.Title.WorkInformationCaption</EasyTrans>
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <div className="shadow mb-5 overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h4 className="text-lg font-medium leading-6 text-gray-900">
                          <EasyTrans>
                            RegisterWork.Element.DescriptionWork
                          </EasyTrans>
                        </h4>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="worktype"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>
                                RegisterWork.Element.CreationType
                              </EasyTrans>
                              <span className="text-red-600">*</span>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.CreationType"
                              Message="Auswählen, ob es sich bei dem Werk um ein Dokument, Audio, Bild, Video, Software, Interaktiv, Daten oder Hardware handelt."
                              TransKey="RegisterWork.Element.HelpCreationType"
                            ></HelpIcon>
                          </div>

                          <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 gap-x-6 lg:grid-cols-2 xl:grid-cols-4 xl:gap-x-8">
                            <WorktypButton
                              type="Document"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeDocument
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Picture"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypePicture
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Audio"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeAudio
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Video"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeVideo
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Interactive"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeInteractive
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Software"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeSoftware
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Hardware"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeHardware
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                            <WorktypButton
                              type="Data"
                              helpMessage={
                                <EasyTrans>
                                  RegisterWork.Element.HelpWorkTypeData
                                </EasyTrans>
                              }
                              onSetType={props.onSetType}
                              typeError={props.typeError}
                              setTypeError={props.setTypeError}
                            ></WorktypButton>
                          </div>
                          {props.typeError ? (
                            <span className="text-red-700">
                              <EasyTrans>
                                RegisterWork.Element.ChooseType
                              </EasyTrans>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>RegisterWork.Element.Title</EasyTrans>
                              <span className="text-red-600">*</span>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.Title"
                              Message="Titel für das Werk vergeben."
                              TransKey="RegisterWork.Element.HelpTitle"
                            ></HelpIcon>
                          </div>
                          <input
                            id="title"
                            type="text"
                            name="title"
                            onChange={(event) =>
                              props.onSetTitle(event.target.value)
                            }
                            onFocus={(event) => props.setTitleError(false)}
                            className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                              props.titleError ? "border-red-700 bg-red-50" : ""
                            }`}
                          />
                          {props.titleError ? (
                            <span className="text-red-700">
                              <EasyTrans>
                                RegisterWork.Element.ChooseTitle
                              </EasyTrans>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="description"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>
                                RegisterWork.Element.Description
                              </EasyTrans>
                              <span className="text-red-600">*</span>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.Description"
                              Message="Eine Beschreibung zum Werk hinzufügen."
                              TransKey="RegisterWork.Element.HelpDescription"
                            ></HelpIcon>
                          </div>
                          <div className="mt-1">
                            <textarea
                              id="description"
                              name="description"
                              rows={3}
                              onChange={(event) =>
                                props.onSetDescription(event.target.value)
                              }
                              onFocus={(event) =>
                                props.setDescriptionError(false)
                              }
                              className={`mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                                props.descriptionError
                                  ? "border-red-700 bg-red-50"
                                  : ""
                              }`}
                              placeholder={t(
                                "RegisterWork.Element.Description",
                              )}
                              defaultValue={""}
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            <EasyTrans>
                              RegisterWork.Element.SubDescription
                            </EasyTrans>
                          </p>
                          {props.descriptionError ? (
                            <span className="text-red-700">
                              <EasyTrans>
                                RegisterWork.Element.ChooseDescription
                              </EasyTrans>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="tags"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>RegisterWork.Element.Tags</EasyTrans>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.Tags"
                              Message="Dem Werk Schlagworte zuordnen, damit danach gefiltert werden kann. Die Tags können auch im Nachhinein hinzugefügt werden."
                              TransKey="RegisterWork.Element.HelpTags"
                            ></HelpIcon>
                          </div>
                          {tagsList.map((x, index) => {
                            return (
                              <input
                                id={x.id}
                                key={index}
                                defaultValue={x.value}
                                onChange={(e) => {
                                  let newList = tagsList;
                                  newList[index].value = e.target.value;
                                  setTagsList(newList);
                                }}
                                type="text"
                                name="tag"
                                className="flex-1 my-2 focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-fairblue-500 enabled:hover:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                              />
                            );
                          })}
                        </div>
                        <AddAndDeleteButton
                          deleteAllowed={tagsList.length >= 1}
                          onAdd={onAddTag}
                          onDelete={onDeleteTag}
                          nameAdd={
                            <EasyTrans>RegisterWork.Element.AddTag</EasyTrans>
                          }
                          nameDelete={
                            <EasyTrans>
                              RegisterWork.Element.DeleteTag
                            </EasyTrans>
                          }
                        ></AddAndDeleteButton>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="select-language"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>Language</EasyTrans>
                            </label>
                            <HelpIcon
                              Title="Language"
                              Message="Die Sprache in welcher das Werk verfügbar ist"
                              TransKey="RegisterWork.Element.HelpLanguage"
                            ></HelpIcon>
                          </div>
                          <select
                            id="select-language"
                            name="select-language"
                            defaultValue={""}
                            onChange={(event) => {
                              props.onSetLanguage(event.target.value);
                              //props.onSetLanguage(ISO6391.getCode(event.target.value));
                            }}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm"
                          >
                            <option value="">
                              <EasyTrans>RegisterWork.Element.NoLang</EasyTrans>
                            </option>
                            {ISO6391.getAllNames().map(
                              (languagename, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={ISO6391.getCode(languagename)}
                                  >
                                    {languagename}
                                  </option>
                                );
                              },
                            )}
                          </select>
                        </div>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="date"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>
                                RegisterWork.Element.CreationDate
                              </EasyTrans>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.CreationDate"
                              Message="Wann wurde das Werk fertiggestellt?"
                              TransKey="RegisterWork.Element.HelpCreationDate"
                            ></HelpIcon>
                          </div>
                          <input
                            id="creationdate"
                            type="date"
                            name="creationdate"
                            onChange={(event) =>
                              props.onSetCreationDate(event.target.value)
                            }
                            className="mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="shadow mb-5 overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h4 className="text-lg font-medium leading-6 text-gray-900">
                          <EasyTrans>
                            RegisterWork.Element.Contributor
                          </EasyTrans>
                        </h4>
                        <div>
                          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                            <label
                              htmlFor="creator"
                              className="block text-sm font-medium text-gray-700"
                            >
                              <EasyTrans>
                                RegisterWork.Element.AuthorTitle
                              </EasyTrans>
                              <span className="text-red-600">*</span>
                            </label>
                            <HelpIcon
                              Title="RegisterWork.Element.AuthorTitle"
                              Message="In der ersten Zeile wird automatisch der eingeloggte Nutzer:in als Urheber:in befüllt. Falls dies nicht dem/der Urheber:in entspricht, muss die erste Zeile geändert werden. Über das Plusicon (+) können weitere Urheber:innen hinzugefügt werden."
                              TransKey="RegisterWork.Element.HelpAuthorTitle"
                            ></HelpIcon>
                          </div>

                          {props.contributorList.map((value, index) => (
                            <WorkCreatorForm
                              key={index}
                              index={index}
                              currentContributor={value}
                              contributorList={props.contributorList}
                              creatorError={props.creatorError}
                              emailError={props.emailError}
                              setEmailError={props.setEmailError}
                              firstnameError={props.firstnameError}
                              setFirstnameError={props.setFirstnameError}
                              lastnameError={props.lastnameError}
                              setLastnameError={props.setLastnameError}
                              percentageError={props.percentageError}
                              setPercentageError={props.setPercentageError}
                              pseudonymError={props.pseudonymError}
                              setPseudonymError={props.setPseudonymError}
                              noHelpNeeded={false}
                              edit={false}
                            ></WorkCreatorForm>
                          ))}

                          <AddAndDeleteButton
                            deleteAllowed={props.contributorList.length >= 2}
                            onAdd={onAddAuthor}
                            onDelete={onDeleteAuthor}
                            nameAdd={
                              <EasyTrans>
                                MyWorks.WorkListElement.AddAuthor
                              </EasyTrans>
                            }
                            nameDelete={
                              <EasyTrans>
                                MyWorks.WorkListElement.DeleteAuthor
                              </EasyTrans>
                            }
                          ></AddAndDeleteButton>

                          {props.creatorError ? (
                            <span className="text-red-700">
                              <EasyTrans>
                                RegisterWork.Element.AtLeastOneAuthor
                              </EasyTrans>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <Collapsible
                          trigger={
                            <div className="flex">
                              <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                                <EasyTrans>
                                  RegisterWork.Element.AdditionalFields
                                </EasyTrans>
                              </h4>
                              <ArrowDownIcon
                                className="flex-initial h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                          }
                        >
                          <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                              <div>
                                <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                                  <label
                                    htmlFor="publisher"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    <EasyTrans>
                                      RegisterWork.Element.Publisher
                                    </EasyTrans>
                                  </label>
                                  <HelpIcon
                                    Title="RegisterWork.Element.Publisher"
                                    Message="Zusätzlicher Herausgeber beispielsweise Buchverlag oder StreamingplattRegisterWork."
                                    TransKey="RegisterWork.Element.HelpPublisher"
                                  ></HelpIcon>
                                </div>
                                <input
                                  id="publisher"
                                  type="text"
                                  name="publisher"
                                  onChange={(event) =>
                                    props.onSetPublisher(event.target.value)
                                  }
                                  className="mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              <div>
                                <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                                  <label
                                    htmlFor="identifier"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    <EasyTrans>
                                      RegisterWork.Element.Identifier
                                    </EasyTrans>
                                  </label>
                                  <HelpIcon
                                    Title="RegisterWork.Element.Identifier"
                                    Message="Kennzeichen mit dem das Werk identifiziert werden kann, beispielsweise ISBN (International Standard Book Number) oder interne Kennzeichen."
                                    TransKey="RegisterWork.Element.HelpIdentifikator"
                                  ></HelpIcon>
                                </div>
                                <input
                                  id="identifier"
                                  type="text"
                                  name="identifier"
                                  onChange={(event) =>
                                    props.onSetIdentifier(event.target.value)
                                  }
                                  className="mt-1 focus:ring-fairblue-500 focus:border-fairblue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto"></div>
                            </div>
                          </div>
                        </Collapsible>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default RegisterWorkInformation;
