import React, { useEffect, useState } from "react";

import AllLicences from "./licencework/AllLicences";
import { ArrowDownIcon } from "@heroicons/react/outline";
import CCKitHelper from "./licencework/CCKitHelper";
import Collapsible from "react-collapsible";
import CommonlyUsedLicences from "./licencework/CommonlyUsedLicences";
import EasyTrans from "../bundel/EasyTrans";
import HelpIcon from "./HelpIcon";
import { useTranslation } from "react-i18next";

//TODO fetch der licences in ApiService auslagern

const RegisterWorkLicence = (props) => {
  const [shortList, setShortList] = useState(true);
  const [ccKit, setCCKit] = useState(false);
  const [longlist, setLongList] = useState(false);
  const [t] = useTranslation();

  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/spdx/license-list-data/master/json/licenses.json",
    ).then((value) => {
      value.json().then((jsonValue) => {
        setLicenses(jsonValue.licenses);
      });
    });
  }, []);

  return (
    <div className=" max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sm:my-6 lg:my-10">
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-fairblue-500">
                <EasyTrans>RegisterWork.Title.AddLicence</EasyTrans>
              </h3>
              <p className="mt-1 text-sm text-gray-700">
                <EasyTrans>RegisterWork.Title.AddLicenceCaption</EasyTrans>
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                  <ul className="flex flex-wrap -mb-px justify-between">
                    <div>
                      <li className="mr-2">
                        <button
                          onClick={() => {
                            setCCKit(false);
                            setShortList(true);
                            setLongList(false);
                          }}
                          className={`cursor-pointer ${
                            shortList
                              ? "inline-block p-4 text-fairblue-500 rounded-t-lg border-b-2 border-fairblue-500 active dark:text-green-500 dark:border-green-500"
                              : "inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-fairdarkgreen hover:border-fairdarkgreen dark:hover:text-gray-300"
                          }`}
                        >
                          <EasyTrans>
                            RegisterWork.License.CommonUsedTitle
                          </EasyTrans>
                        </button>
                      </li>
                    </div>
                    <div>
                      <li className="mr-2">
                        <button
                          onClick={() => {
                            setCCKit(true);
                            setShortList(false);
                            setLongList(false);
                          }}
                          className={`cursor-pointer ${
                            ccKit
                              ? "inline-block p-4 text-fairblue-500 rounded-t-lg border-b-2 border-fairblue-500 active dark:text-green-500 dark:border-green-500"
                              : "inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-fairdarkgreen hover:border-fairdarkgreen dark:hover:text-gray-300"
                          }`}
                          aria-current="page"
                        >
                          <EasyTrans>
                            RegisterWork.License.LicenseKit.Title
                          </EasyTrans>
                        </button>
                      </li>
                    </div>
                    <div>
                      <li className="mr-2">
                        <button
                          onClick={() => {
                            setCCKit(false);
                            setShortList(false);
                            setLongList(true);
                          }}
                          className={`cursor-pointer ${
                            longlist
                              ? "inline-block p-4 text-fairblue-500 rounded-t-lg border-b-2 border-fairblue-500 active dark:text-green-500 dark:border-green-500"
                              : "inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-fairdarkgreen hover:border-fairdarkgreen dark:hover:text-gray-300"
                          }`}
                        >
                          <EasyTrans>
                            RegisterWork.License.AllLicensesTitle
                          </EasyTrans>
                        </button>
                      </li>
                    </div>
                  </ul>
                </div>

                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    {shortList ? (
                      <CommonlyUsedLicences
                        workType={props.workType}
                        onSetLicence={props.onSetLicence}
                        licences={licenses}
                        licenceError={props.licenceError}
                        setLicenceError={props.setLicenceError}
                      ></CommonlyUsedLicences>
                    ) : (
                      <></>
                    )}

                    {ccKit ? (
                      <CCKitHelper
                        onSetLicence={props.onSetLicence}
                        licenceError={props.licenceError}
                        setLicenceError={props.setLicenceError}
                      ></CCKitHelper>
                    ) : (
                      <></>
                    )}

                    {longlist ? (
                      <AllLicences
                        onSetLicence={props.onSetLicence}
                        licences={licenses}
                        licenceError={props.licenceError}
                        setLicenceError={props.setLicenceError}
                      ></AllLicences>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="shadow mb-5 overflow-hidden sm:rounded-md">
                  <div
                    className={`px-4 py-5 bg-white space-y-6 sm:p-6 ${
                      props.licenceError
                        ? "border-2 border-red-700 bg-red-50"
                        : ""
                    }`}
                  >
                    <div className="flex">
                      <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900 ">
                        {props.licence === undefined
                          ? t("RegisterWork.License.NoLicense")
                          : props.licence}{" "}
                        <EasyTrans>
                          RegisterWork.License.CommonSelected
                        </EasyTrans>
                        <span className="text-red-600">*</span>
                      </h4>
                    </div>
                    {props.licenceError ? (
                      <span className="text-red-700">
                        <EasyTrans>
                          RegisterWork.License.CommonSelectLicense
                        </EasyTrans>
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Dalicc hidden
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <Collapsible
                      trigger={
                        <div className="flex">
                          <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                            <EasyTrans>
                              RegisterWork.License.AdditionalFields
                            </EasyTrans>
                          </h4>
                          <ArrowDownIcon
                            className="flex-initial h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                      }
                    >
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div>
                            <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
                              <label
                                htmlFor="darlicURL"
                                className="block text-sm font-medium text-gray-700"
                              >
                                <EasyTrans>
                                  RegisterWork.License.DalicURLLicence
                                </EasyTrans>
                              </label>
                              <HelpIcon
                                Title="Dalic URL Licence"
                                Message="Dalic URL Licence"
                              ></HelpIcon>
                            </div>
                            <input
                              id="darlicURL"
                              type="text"
                              name="darlicURL"
                              onChange={(event) =>
                                props.onSetDaliccLicence(event.target.value)
                              }
                              className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default RegisterWorkLicence;
