import ActiveFeature from "./ActiveFeature";
import EasyTrans from "../bundel/EasyTrans";
import InactiveFeature from "./InactiveFeature";
import React from "react";

const PriceCard = (props) => {
  return (
    <div className="p-4 w-full max-w-lg lg:max-w-sm bg-white rounded-lg border shadow-md sm:p-8 m-4 lg:m-0 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-between">
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400 text-left">
          <EasyTrans>{props.nameLangKey}</EasyTrans>
        </h5>
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400 text-right">
          {props.storage}
        </h5>
      </div>

      <div className="flex items-baseline text-gray-900 dark:text-white">
        <span className="text-2xl font-semibold">€</span>
        <span className="text-3xl font-extrabold tracking-tight">
          {props.price}
        </span>
        <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
          {props.price != 0 ? (
            <>
              {" "}
              / <EasyTrans>Landingpage.Year</EasyTrans> (
              <EasyTrans>Landingpage.MWST</EasyTrans>)
            </>
          ) : (
            <div className="invisible">
              {" "}
              / 3 € <EasyTrans>Landingpage.Year</EasyTrans> (
              <EasyTrans>Landingpage.MWST</EasyTrans>)
            </div>
          )}
        </span>
      </div>
      <a href={props.goto}>
        <button onClick={props.onClick} className="w-full flex items-center justify-center mt-7 px-8 py-3 border border-transparent text-base font-medium rounded-md text-fairblack bg-fairdarkgreen hover:bg-fairblue-500 hover:text-fairwhite md:py-4 md:text-lg md:px-10">
          {props.price != 0 ? (
            <> Shop </>
          ) : (
            <EasyTrans>Landingpage.LandingpageButton1</EasyTrans>
          )}
        </button>
      </a>
    </div>
  );
};

export default PriceCard;
