import React from "react";
import SideMenu from "./SideMenu";
import LanguageService from "../../services/LanguageService";
import ISO6391 from "iso-639-1"; //https://www.npmjs.com/package/iso-639-1
import EasyTrans from "../../components/bundel/EasyTrans";

const Settings = () => {
  return (
    <div className="flex">
      <SideMenu></SideMenu>
      <section className="bg-white h-screen w-screen flex p-10 flex-col">
        <div>
          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto">
            <label
              htmlFor="select-language"
              className="block text-sm font-medium text-gray-700"
            >
              <EasyTrans>Language</EasyTrans>
            </label>
          </div>
          <select
            id="select-language"
            name="select-language"
            defaultValue={LanguageService.getLanguage()}
            onChange={(event) => {
              let code = event.target.value;
              LanguageService.changeLanguage(code).then((value) => {
                console.log("ok");
              });
            }}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
          >
            {ISO6391.getLanguages(["en", "de"]).map((languagename) => {
              // {ISO6391.getLanguages(LanguageService.getSupportedLanguages()).map((languagename) => {
              return (
                <option value={languagename.code}>
                  {languagename.nativeName}
                </option>
              );
            })}
          </select>
        </div>
      </section>
    </div>
  );
};

export default Settings;
