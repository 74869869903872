import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

const Taglist = (props) => {
  const tagList = [
    "CC",
    "Creative Commons",
    "fairregister",
    "AI",
    "NFTs",
    "fairkom",
    "Open Source",
  ];

  const [selectedLocalTags, setSelectedLocalTags] = useState([]);

  const handleTagClick = (tag) => {
    if (selectedLocalTags.includes(tag)) {
      setSelectedLocalTags(selectedLocalTags.filter((t) => t !== tag));
      props.setSelectedTags(selectedLocalTags.filter((t) => t !== tag));
    } else {
      setSelectedLocalTags([...selectedLocalTags, tag]);
      props.setSelectedTags([...selectedLocalTags, tag]);
    }
  };

  const tagClasses =
    "rounded-full text-white px-4 py-2 m-1 cursor-pointer transition-colors duration-300";

  const getTagColor = (tag) =>
    selectedLocalTags.includes(tag) ? "bg-fairblue-500" : "bg-gray-500";

  const getTagIcon = (tag) =>
    selectedLocalTags.includes(tag) ? (
      <CheckCircleIcon className="h-5 w-5 mr-1" />
    ) : (
      <PlusCircleIcon className="h-5 w-5 mr-1" />
    );

  return (
    <div className="flex flex-wrap justify-start h-auto">
      {tagList.map((tag) => (
        <div
          key={tag}
          className={`${tagClasses} ${getTagColor(tag)}`}
          onClick={() => handleTagClick(tag)}
        >
          <div className="flex items-center">
            {getTagIcon(tag)}
            {tag}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Taglist;
