import React from "react";
import UserService from "../services/UserService";
import EasyTrans from "../components/bundel/EasyTrans";

const ExceptionLoginPage = () => {
  return (
    <section className="bg-white h-screen flex">
      <div className="py-8 px-4 mx-auto max-w-screen-xl  lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center ">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
            <EasyTrans>ErrorHandling.MissingLogin.CodeDescription</EasyTrans>
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            <EasyTrans>ErrorHandling.MissingLogin.Header</EasyTrans>
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            <EasyTrans>ErrorHandling.MissingLogin.Text</EasyTrans>
          </p>
          <div className="flex justify-around">
            <button
              className="basis-1/2 p-3"
              onClick={() => UserService.doLogin()}
            >
              <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-fairblack bg-fairdarkgreen hover:bg-fairblue-500 hover:text-fairwhite md:py-4 md:text-lg md:px-10">
                <EasyTrans>ErrorHandling.MissingLogin.standardButton</EasyTrans>
              </span>
            </button>
            <button
              className="basis-1/2 p-3"
              onClick={() => UserService.doRegister()}
            >
              <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-fairblack bg-fairbrightgreen hover:bg-fairblue-500 hover:text-fairwhite md:py-4 md:text-lg md:px-10">
                <EasyTrans>ErrorHandling.MissingLogin.secondButton</EasyTrans>
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExceptionLoginPage;
