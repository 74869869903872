import React from "react";

function InputField(props) {
  const { label, type, name, id, defaultValue, disabled } = props;

  if (!props.searchWork || props.defaultValue) {
    return (
      <div className="bg-white items-center px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <input
            type={type}
            name={name}
            id={id}
            defaultValue={defaultValue}
            className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
            disabled={disabled}
          />
        </dd>
      </div>
    );
  }

  return null;
}

export default InputField;
