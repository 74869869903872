import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ApiService from "../services/ApiService";
import PublicWorkCard from "../components/bundel/PublicWorkCard";

const WorkPage = () => {
    const [work, setWork] = useState(null);
    const { grid } = useParams();
    
    const fetchPublicWork = async (grid) => {
        const fetchedWork = await ApiService.getPublicWork(grid);
        setWork(fetchedWork);
    }

    useEffect(() => {
        fetchPublicWork(grid);
    }, [grid]);

    if (!work) return null;

    return <PublicWorkCard work={work} />;
};

export default WorkPage;
