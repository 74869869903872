import { Route, Routes } from "react-router-dom"; //https://reactrouter.com/docs/en

import Certificates from "./pages/Settings/Certificates";
import Dashboard from "./pages/Settings/Dashboard";
import Footer from "./components/bundel/Footer";
import Landingpage from "./pages/LandingPage";
import MyWorks from "./pages/MyWorksPage";
import Navbar from "./components/bundel/Navbar";
import Profil from "./pages/Settings/Profil";
import React from "react";
import RegisterWork from "./pages/RegisterWorkPage";
import RenderOnAuthenticated from "./keycloak/RenderOnAuthenticated";
import SearchWorks from "./pages/SearchWorksPage";
import Settings from "./pages/Settings/Settings";
import Works from "./pages/Settings/Works";
import WorkPage from "./pages/WorkPage";

//TODO Implement nested routes
function App(props) {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="bg-fairgray flex-grow overflow-y-auto">
        <Routes>
          <Route path="/" index element={<Landingpage />} />
          <Route path="/works/:grid" element={<WorkPage />} />
          <Route path="/searchworks" element={<SearchWorks />} />
          <Route
            path="/registerwork"
            element={
              <RenderOnAuthenticated>
                <RegisterWork />
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/myworks"
            element={
              <RenderOnAuthenticated>
                <MyWorks />
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/settings"
            element={
              <RenderOnAuthenticated>
                <Profil />
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/profil"
            element={
              <RenderOnAuthenticated>
                <Profil />
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/certificates"
            element={
              <RenderOnAuthenticated>
                <Certificates></Certificates>
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/config"
            element={
              <RenderOnAuthenticated>
                <Settings />
              </RenderOnAuthenticated>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RenderOnAuthenticated>
                <Dashboard></Dashboard>
              </RenderOnAuthenticated>
            }
          />
        </Routes>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
