//import React from 'react';
/* This example requires Tailwind CSS v2.0+ */

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

import EasyTrans from "./EasyTrans";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import UserService from "../../services/UserService";
//example from https://tailwindui.com/preview --> changed
import fairkomlogo from "./../../../src/assets/logo/fairregister_logo.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [landingpage, setLandingpage] = useState(true);
  const [searchwork, setSearchwork] = useState(true);
  const [registerwork, setRegisterwork] = useState(true);
  const [myworks, setMyWorks] = useState(true);

  useEffect(() => {
    resetAllNavStates();
    switch (window.location.pathname) {
      case "/":
        setLandingpage(true);
        break;
      case "/searchworks":
        setSearchwork(true);
        break;
      case "/registerwork":
        setRegisterwork(true);
        break;
      case "/myWorks":
        setMyWorks(true);
        break;
    }
  });

  function resetAllNavStates() {
    setLandingpage(false);
    setSearchwork(false);
    setRegisterwork(false);
    setMyWorks(false);
  }

  return (
    <Disclosure as="nav" className="bg-fairwhite border-b-2 border-fairblue-500">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md hover:border-2 hover:border-fairblue-500 focus:fairblue-500">
                  <span className="sr-only">
                    <EasyTrans>Navigation.SrMenuOpen</EasyTrans>
                  </span>
                  {open ? (
                    <XIcon
                      className="block h-6 w-6 text-fairblue-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuIcon
                      className="block h-6 w-6 text-fairblue-500"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <NavLink
                    to="/"
                    onClick={() => {
                      resetAllNavStates();
                      setLandingpage(true);
                    }}
                  >
                    <img
                      className="hidden lg:block h-10 w-auto"
                      src={fairkomlogo}
                      alt="fairkomlogo"
                    />
                  </NavLink>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <NavLink
                      to="/"
                      onClick={() => {
                        resetAllNavStates();
                        setLandingpage(true);
                      }}
                      className={classNames(
                        landingpage ? "border-b-2 border-fairblue-500" : "",
                        "px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                      )}
                      aria-current={true ? "page" : undefined}
                    >
                      <EasyTrans>Navigation.Landingpage</EasyTrans>
                    </NavLink>
                    <NavLink
                      to="/searchworks"
                      onClick={() => {
                        resetAllNavStates();
                        setSearchwork(true);
                      }}
                      className={classNames(
                        searchwork ? "border-b-2 border-fairblue-500" : "",
                        "px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                      )}
                      aria-current={true ? "page" : undefined}
                    >
                      <EasyTrans>Navigation.Worksearch</EasyTrans>
                    </NavLink>
                    <NavLink
                      to="/registerwork"
                      onClick={() => {
                        resetAllNavStates();
                        setRegisterwork(true);
                      }}
                      className={classNames(
                        registerwork ? "border-b-2 border-fairblue-500" : "",
                        "px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                      )}
                      aria-current={true ? "page" : undefined}
                    >
                      <EasyTrans>Navigation.Workregister</EasyTrans>
                    </NavLink>
                    <NavLink
                      to="/myWorks"
                      onClick={() => {
                        resetAllNavStates();
                        setMyWorks(true);
                      }}
                      className={classNames(
                        myworks ? "border-b-2 border-fairblue-500" : "",
                        "px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                      )}
                      aria-current={true ? "page" : undefined}
                    >
                      <EasyTrans>Navigation.MyWorks</EasyTrans>
                    </NavLink>
                    {/*TODO Restliche NavLinks einbauen und neue grafische aktivierung hinzufügen*/}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden p-2 lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  {UserService.isLoggedIn() ? (
                    <NavLink
                      to="/profil"
                      className="px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md"
                    >
                      {UserService.getDisplayName()}
                    </NavLink>
                  ) : (
                    <button
                      onClick={() => UserService.doLogin()}
                      className="px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md"
                    >
                      <EasyTrans>Navigation.Login</EasyTrans>
                    </button>
                  )}
                </div>
                <Menu>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}
                          >
                            <EasyTrans>Navigation.Profil</EasyTrans>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}
                          >
                            <EasyTrans>Navigation.Logout</EasyTrans>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <NavLink
                to="/"
                onClick={() => {
                  resetAllNavStates();
                  setLandingpage(true);
                }}
              >
                <Disclosure.Button
                  as="a"
                  className={classNames(
                    landingpage ? "border-b-2 border-fairblue-500" : "",
                    "block px-3 py-2 text-base px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                  )}
                  aria-current={false ? "page" : undefined}
                >
                  <EasyTrans>Navigation.Landingpage</EasyTrans>
                </Disclosure.Button>
              </NavLink>
              <NavLink
                to="/searchworks"
                onClick={() => {
                  resetAllNavStates();
                  setSearchwork(true);
                }}
              >
                <Disclosure.Button
                  as="a"
                  className={classNames(
                    searchwork ? "border-b-2 border-fairblue-500" : "",
                    "block px-3 py-2 text-base px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                  )}
                  aria-current={false ? "page" : undefined}
                >
                  <EasyTrans>Navigation.Worksearch</EasyTrans>
                </Disclosure.Button>
              </NavLink>
              <NavLink
                to="/registerwork"
                onClick={() => {
                  resetAllNavStates();
                  setRegisterwork(true);
                }}
              >
                <Disclosure.Button
                  as="a"
                  className={classNames(
                    registerwork ? "border-b-2 border-fairblue-500" : "",
                    "block px-3 py-2 text-base px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                  )}
                >
                  <EasyTrans>Navigation.Workregister</EasyTrans>
                </Disclosure.Button>
              </NavLink>
              <NavLink
                to="/myWorks"
                onClick={() => {
                  resetAllNavStates();
                  setMyWorks(true);
                }}
              >
                <Disclosure.Button
                  as="a"
                  className={classNames(
                    myworks ? "border-b-2 border-fairblue-500" : "",
                    "block px-3 py-2 text-base px-3 py-2 text-sm font-medium text-fairblue-500 hover:bg-fairblue-500 hover:text-fairgray hover:rounded-md",
                  )}
                >
                  <EasyTrans>Navigation.MyWorks</EasyTrans>
                </Disclosure.Button>
              </NavLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
//Disclosure.Panel Hamburger Icon BUG
export default Navbar;
