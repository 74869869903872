import React, { useEffect, useState } from "react";

import EasyTrans from "../../bundel/EasyTrans";

const CommonlyUsedLicences = (props) => {
  //#region States
  const [licenceLink, setLicenceLink] = useState("");
  const [commonlyUsedLicences, setCommonlyUsedLicences] = useState([]);
  //#endregion

  //#region Methods
  useEffect(() => {
    props.onSetLicence(props.spdxIdentifer);
    SetLicenceLinkURL(props.spdxIdentifer);
    setCommonlyUsedLicences(props.licences);
  }, []);

  //TODO Check wegen //No assertion and Full copyright should be listed at all categories.
  //No assertion should be listed at all categories ?? Review
  useEffect(() => {
    var filterLicences = [];
    switch (props.workType) {
      case "Picture":
      case "Interaktiv":
      case "Interactiv":
      case "Interactive":
        //Picture, Interactive zusätzlich: LAL-1.3
        filterLicences.push(
          props.licences.find((e) => e.licenseId == "LAL-1.3"),
        );
      case "Document":
      case "Audio":
      case "Video":
        //Document, Picture, Audio, Video, Interactive: CC-BY-4.0, CC-BY-SA-4.0, CC0-1.0, EUPL-1.2
        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC0-1.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC-BY-4.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC-BY-SA-4.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "EUPL-1.2"),
        );

        setCommonlyUsedLicences(filterLicences);
        break;
      case "Hardware":
        //Hardware: CERN-OHL-P-2.0, CERN-OHL-S-2.0
        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CERN-OHL-P-2.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CERN-OHL-S-2.0"),
        );

        setCommonlyUsedLicences(filterLicences);
        break;
      case "Software":
        //Software: list all licenses in this short list except CERN-OHL-P-2.0, CERN-OHL-S-2.0, LAL-1.3, ODbL-1.0
        //checken
        var filteredLicenses = props.licences.filter(function (license) {
          return (
            license.licenseId !== "CERN-OHL-P-2.0" &&
            license.licenseId !== "CERN-OHL-S-2.0" &&
            license.licenseId !== "LAL-1.3" &&
            license.licenseId !== "ODbL-1.0"
          );
        });
        setCommonlyUsedLicences(filteredLicenses);
        break;
      case "Data":
        //Data: CC-BY-4.0, CC-BY-SA-4.0, CC0-1.0, ODbL-1.0
        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC0-1.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC-BY-4.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "CC-BY-SA-4.0"),
        );

        filterLicences.push(
          props.licences.find((e) => e.licenseId == "ODbL-1.0"),
        );

        setCommonlyUsedLicences(filterLicences);
        break;
      default:
        setCommonlyUsedLicences(props.licences);
    }
  }, [props.workType, props.licences]);

  function SetLicenceLinkURL(spdxIdentifer) {
    props.licences.map((licence) => {
      if (licence.licenseId == spdxIdentifer) {
        setLicenceLink(licence.detailsUrl);
      }
    });
  }
  //#endregion

  //#region View
  return (
    <>
      <select
        name="select-licence"
        id="select-licence"
        defaultValue={props.spdxIdentifer}
        onClick={(event) => {
          props.setLicenceError(false);
        }}
        onChange={(event) => {
          props.setLicenceError(false);
          const spdxID = event.target.value;
          if (spdxID == 0) {
            props.onSetLicence("");
            setLicenceLink("");
          } else {
            SetLicenceLinkURL(spdxID);
            props.onSetLicence(spdxID);
          }
        }}
        className={`mt-1 block w-full py-2 px-3 border bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm ${
          props.licenceError
            ? "border-2 border-red-700 bg-red-50"
            : " border-gray-300 "
        }`}
      >
        <option value="FullCopyright">
          <EasyTrans>RegisterWork.License.CommonRightsReserved</EasyTrans>
        </option>
        <option value="NoAssertion">
          <EasyTrans>RegisterWork.License.NoAssertion</EasyTrans>
        </option>
        {commonlyUsedLicences.map((licence) => {
          return (
            <option key={licence.referenceNumber} value={licence.licenseId}>
              {licence.name}
            </option>
          );
        })}
      </select>

      {licenceLink ? (
        <a className="text-sm text-fairblue-500" href={licenceLink}>
          <EasyTrans>RegisterWork.License.FurtherInformation</EasyTrans>:{" "}
          {licenceLink}
        </a>
      ) : (
        <div></div>
      )}
    </>
  );
  //#endregion
};

export default CommonlyUsedLicences;
