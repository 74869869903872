import React, { useState } from "react";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import EasyTrans from "../../components/bundel/EasyTrans";
import { t } from "i18next";

const SideMenu = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <aside className={`transition-all`} aria-label="Sidebar">
      <div
        className={`h-screen overflow-y-auto py-4 pl-3 bg-gray-50 rounded dark:bg-gray-800 ${
          expanded ? "pr-3" : "pr-0"
        }`}
      >
        <ul>
          <li>
            <button
              onClick={() => setExpanded(!expanded)}
              className="flex text-left p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              title={t(
                expanded
                  ? "Settings.SideMenu.Reduce"
                  : "Settings.SideMenu.Expand",
              )}
            >
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                {expanded ? (
                  <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                ) : (
                  <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                )}
              </svg>
              {true ? (
                <span
                  className={`transition-all ${
                    expanded ? "ml-3" : "text-none"
                  }`}
                >
                  {expanded ? (
                    <EasyTrans>Settings.SideMenu.Reduce</EasyTrans>
                  ) : (
                    <EasyTrans>Settings.SideMenu.Expand</EasyTrans>
                  )}
                </span>
              ) : (
                <></>
              )}
            </button>
          </li>
          <li>
            <Link
              to="/profil"
              className="flex text-left p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              title={t("Settings.SideMenu.Profil")}
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {true ? (
                <span
                  className={`transition-all ${
                    expanded ? "ml-3" : "text-none"
                  }`}
                >
                  <EasyTrans>Settings.SideMenu.Profil</EasyTrans>
                </span>
              ) : (
                <></>
              )}
            </Link>
          </li>
          <li>
            <Link
              to="/config"
              className="flex text-left p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              title={t("Settings.SideMenu.Settings")}
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  clipRule="evenodd"
                ></path>
              </svg>
              {true ? (
                <span
                  className={`transition-all ${
                    expanded ? "ml-3" : "text-none"
                  }`}
                >
                  <EasyTrans>Settings.SideMenu.Settings</EasyTrans>
                </span>
              ) : (
                <></>
              )}
            </Link>
          </li>
          <li>
            <button
              onClick={() => UserService.doLogout()}
              className="flex text-left p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              title={t("Settings.SideMenu.Logout")}
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {true ? (
                <span
                  className={`transition-all ${
                    expanded ? "ml-3" : "text-none"
                  }`}
                >
                  <EasyTrans>Settings.SideMenu.Logout</EasyTrans>
                </span>
              ) : (
                <></>
              )}
            </button>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default SideMenu;
