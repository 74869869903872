import React from "react";
import {
  DocumentAddIcon,
  VolumeUpIcon,
  PhotographIcon,
  VideoCameraIcon,
  CodeIcon,
  CursorClickIcon,
  DatabaseIcon,
  ChipIcon,
} from "@heroicons/react/outline";

const WorkTypIcon = (props) => {
  switch (props.type) {
    case "Hardware":
      return (
        <ChipIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Data":
      return (
        <DatabaseIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Interactive":
      return (
        <CursorClickIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Software":
      return (
        <CodeIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Video":
      return (
        <VideoCameraIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Picture":
      return (
        <PhotographIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Document":
      return (
        <DocumentAddIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    case "Audio":
      return (
        <VolumeUpIcon
          className={`items-center h-20 w-20 text-${props.color}`}
          aria-hidden="true"
        />
      );
    default:
      console.log("Worktyp noch nicht ausgewählt");
  }
};

export default WorkTypIcon;
