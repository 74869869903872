import React from "react";

import PublicWorkCard from "./PublicWorkCard";
import WorkCard from "./WorkCard";

//TODO add save elements into Backend

const UserWorkListElement = (props) => {
  return (
    <>
      {props.publicWork ? (
        <PublicWorkCard work={props.userWork}></PublicWorkCard>
      ) : (
        <WorkCard
          key={props.userWork.id}
          userWork={props.userWork}
          searchWork={true}
          editable="true"
        ></WorkCard>
      )}
    </>
  );
};

export default UserWorkListElement;
