import React, { useEffect, useState } from "react";

import ApiService from "../services/ApiService";
import Controlbar from "../components/bundel/Controlbar";
import EasyTrans from "../components/bundel/EasyTrans";
import Pagination from "../components/bundel/Pagination";
import Spinner from "../components/bundel/Spinner";
import UserWorkListElement from "../components/bundel/UserWorkListElement";
import WorkTypIcon from "../components/registerwork/WorkTypIcon";

const SearchWorksPage = () => {
  const [workView, setWorkView] = useState(true);
  const [userWorkList, setUserWorkList] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState();
  const [pagination, setPagination] = useState();

  const [title, setTitle] = useState();
  const [workType, setWorkType] = useState();
  const [licence, setLicence] = useState();
  const [sort, setSort] = useState("asc");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await ApiService.getPublicWorkList(
        title,
        workType,
        licence,
        selectedTags,
        page,
        itemsPerPage,
        sort,
      );
      setUserWorkList(result?.content);

      setTotalPages(result?.totalPages);
      setTotalElements(result?.totalElements);
      setPagination(result?.pageable);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, itemsPerPage]);

  const handleApplyClick = () => {
    fetchData();
  };

  const onSetTitle = (title) => {
    setTitle(title);
  };

  const onSetWorkType = (workType) => {
    setWorkType(workType);
  };

  const onSetLicence = (licence) => {
    setLicence(licence);
  };

  const onSetSort = (sort) => {
    setSort(sort);
  };

  return (
    <div>
      <Controlbar
        handleApplyClick={handleApplyClick}
        workView={workView}
        setWorkView={setWorkView}
        onSetTitle={onSetTitle}
        onSetWorkType={onSetWorkType}
        onSetLicence={onSetLicence}
        onSetSort={onSetSort}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      ></Controlbar>
      {isLoading ? (
        <div className="flex justify-center">
          <div className="flex justify-center column p-10">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 my-2">
          {workView ? (
            userWorkList.length > 0 ? (
              userWorkList.map((userWork, index) => {
                console.log(userWork);
                return (
                  <UserWorkListElement
                    key={index}
                    userWork={userWork}
                    searchWork={true}
                    editable="false"
                    publicWork={true}
                  ></UserWorkListElement>
                );
              })
            ) : (
              <div className="flex items-center justify-center ">
                <p className="text-2xl text-gray-500">
                  <EasyTrans>NoElementsFound</EasyTrans>
                </p>
              </div>
            )
          ) : (
            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {userWorkList.map((work) => (
                <a key={work.id} href={work.href} className="group">
                  <div className="aspect-w-1 aspect-h-1 bg-fairbrightgreen rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                    <div className="flex justify-center items-center w-full h-full">
                      <WorkTypIcon
                        type="Document" //TODO Worktype zurückgeben work.type
                        className="w-full h-full object-center object-cover group-hover:opacity-75"
                      ></WorkTypIcon>
                    </div>
                  </div>
                  <h3 className="mt-4 text-sm text-gray-700">{work.licence}</h3>
                  <p className="mt-1 text-lg font-medium text-gray-900">
                    {work.title}
                  </p>
                </a>
              ))}
            </div>
          )}
          {totalElements && (
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalElements={totalElements}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchWorksPage;
