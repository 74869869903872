import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

import EasyTrans from "./EasyTrans";
import PageLink from "../PageLink";
import React from "react";
import { Trans } from "react-i18next";

const Pagination = ({
  page,
  setPage,
  totalPages,
  itemsPerPage,
  setItemsPerPage,
  totalElements,
}) => {
  const visiblePages = 5;
  let offset = 2;

  if (page < offset) {
    offset = page;
  } else if (page > totalPages - visiblePages + offset) {
    offset = visiblePages - (totalPages - page);
  }

  const startPage = Math.max(0, page - offset);
  const endPage = Math.min(totalPages - 1, page - offset + visiblePages);

  const pageLinks = [];
  for (let i = startPage; i <= endPage; i++) {
    pageLinks.push(
      <PageLink key={i} index={i} currentPage={page} setPage={setPage} />,
    );
  }

  const canGoToPreviousPage = page > 0;
  const canGoToNextPage = page < totalPages - 1;

  const getElementsFrom = () => {
    return page === 0 ? 1 : page + itemsPerPage;
  };

  const getElementsTo = () => {
    if (canGoToNextPage) {
      return page === 0 ? page + itemsPerPage : page * itemsPerPage;
    }

    return totalElements;
  };

  return (
    <div>
      <div className="my-2">
        <div className="border-b border-gray-200"></div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            <Trans
              values={{
                from: getElementsFrom(),
                to: getElementsTo(),
                all: totalElements,
              }}
            >
              SearchWorks.ShowingResults
            </Trans>
          </p>
        </div>
        <div>
          <label htmlFor="itemsPerPage" className="text-sm text-gray-700 mr-2">
            <EasyTrans>SearchWorks.Show</EasyTrans>
          </label>
          <select
            id="itemsPerPage"
            name="itemsPerPage"
            className="border border-gray-300 rounded-md text-sm"
            value={itemsPerPage}
            defaultValue={20}
            onChange={(event) => {
              var value = event.target.value;

              setPage(0);
              setItemsPerPage(parseInt(event.target.value));
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="10">15</option>
            <option value="20">20</option>
          </select>
          <label htmlFor="itemsPerPage" className="text-sm text-gray-700 ml-2">
            <EasyTrans>SearchWorks.ItemsPerPage</EasyTrans>
          </label>
        </div>
        <div className="flex justify-center">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => setPage(0)}
              disabled={!canGoToPreviousPage}
              className={`${
                canGoToPreviousPage ? "hover:bg-gray-100 " : "bg-gray-100 "
              }relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              <span className="sr-only">First page</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => setPage(page - 1)}
              disabled={!canGoToPreviousPage}
              className={`${
                canGoToPreviousPage ? "hover:bg-gray-100 " : "bg-gray-100 "
              }relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              <span className="sr-only">Previous page</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageLinks}
            <button
              onClick={() => setPage(page + 1)}
              disabled={!canGoToNextPage}
              className={`${
                canGoToNextPage ? "hover:bg-gray-100 " : "bg-gray-100 "
              }relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              <span className="sr-only">Next page</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={() => {
                setPage(totalPages - 1);
              }}
              disabled={!canGoToNextPage}
              className={`${
                canGoToNextPage ? "hover:bg-gray-100 " : "bg-gray-100 "
              }relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 bg-white rounded-r-md hover:text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              <span className="sr-only">
                <EasyTrans>SearchWorks.SRNext</EasyTrans>
              </span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
