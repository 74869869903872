import {
  ArrowDownIcon,
  DownloadIcon,
  FingerPrintIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";

import AddAndDeleteButton from "./AddAndDeleteButton";
import ApiService from "../../services/ApiService";
import Collapsible from "react-collapsible";
import EasyTrans from "./EasyTrans";
import InputField from "./InputField";
import WorkCreatorFormView from "./WorkCreatorFormView";
import moment from "moment";
import WorkMetaInfoView from "./WorkMetaInfoView";

//TODO add save elements into Backend

const WorkCard = (props) => {
  const [edit, setEdit] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [tagsList, setTagsList] = useState([]);
  const [newTags, setNewTags] = useState([]);

  const [participantList, setParticipantList] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);

  const [creatorList, setCreatorList] = useState([]);

  let inputID = 0;
  let inputParticipantID = -1;

  const copyToClipboard = (grid) => {
    const url = `${window.location.host}/works/${grid}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  function onAddTag() {
    let newTagsList = tagsList;

    let tagObject = {
      id: ++inputID,
      value: "",
    };

    newTagsList.push(tagObject);
    setNewTags(newTagsList);
    setTagsList([...newTagsList]);
  }

  function onDeleteTag() {
    tagsList.pop();
    setTagsList([...tagsList]);
  }

  function onAddParticipant() {
    let newParticipantList = participantList;

    let participantObject = {
      id: ++inputParticipantID,
      value: {
        firstName: "",
        lastName: "",
        email: "",
        pseudonym: "",
        percentage: "",
        url: "",
      },
    };

    newParticipantList.push(participantObject);
    setNewParticipants(newParticipantList);
    setParticipantList([...newParticipantList]);
  }

  function onDeleteParticipant() {
    participantList.pop();
    setTagsList([...participantList]);
  }

  function onCreatorChanged(valueParameter, index) {
    let newList = participantList;
    newList[index].value = valueParameter;
    setParticipantList(newList);
  }

  return (
    <>
      <div className="shadow border-fairblue-500 border-2 mb-5 overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white hover:shadow-inner space-y-6 sm:p-6">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between">
                <div className="flex-grow">
                  <div className="bg-white px-4 py-1">
                    <h4 className="w-full text-lg font-medium leading-6 text-gray-900">
                      {props.userWork.title}
                    </h4>
                  </div>
                </div>
                <div className="flex items-center justify-center space-x-2">
                  {props.editable === "true" && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        ApiService.downloadWork(
                          props.userWork.id,
                          props.userWork.filename,
                        );
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <div className="relative w-12 h-12 rounded-full border-2 border-fairblue-500 hover:bg-fairblue-500 hover:text-white transition-colors duration-300">
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <DownloadIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {props.editable === "false" && (
                    <div
                      onClick={(event) => {
                        console.log(props.userWork.grid);
                        event.stopPropagation();
                        ApiService.downloadPublicWork(
                          props.userWork.grid,
                          props.userWork.filename,
                        );
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <div className="relative w-12 h-12 rounded-full border-2 border-fairblue-500 hover:bg-fairblue-500 hover:text-white transition-colors duration-300">
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <DownloadIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {props.editable === "true" && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        ApiService.downloadCertificate(props.userWork.id);
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <div className="relative w-12 h-12 rounded-full border-2 border-fairblue-500 hover:bg-fairblue-500 hover:text-white transition-colors duration-300">
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <FingerPrintIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {props.editable === "true" && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        copyToClipboard(props.userWork.grid);
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <div className="relative w-12 h-12 rounded-full border-2 border-fairblue-500 hover:bg-fairblue-500 hover:text-white transition-colors duration-300">
                          <div
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          >
                            <ShareIcon className="h-6 w-6" aria-hidden="true" />
                            {tooltipVisible && (
                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 p-2 bg-black text-white text-xs rounded-md">
                              Copied
                            </div>
                          )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-center">
                    <div className="relative w-12 h-12 rounded-full border-2 border-fairblue-500 hover:bg-fairblue-500 hover:text-white transition-colors duration-300">
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <ArrowDownIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <dl>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="mt-5 shadow border-fairblue-500 border-2 mb-5 overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <Collapsible
                      trigger={
                        <div className="flex">
                          <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                            <EasyTrans>MyWorks.WorkListElement.Info</EasyTrans>
                          </h4>
                          <ArrowDownIcon
                            className="flex-initial h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                      }
                    >
                      <dl className="mt-7">
                        {!props.publicWork && (
                          <InputField
                            label={
                              <EasyTrans>
                                MyWorks.WorkListElement.RegistrationStatus
                              </EasyTrans>
                            }
                            type="text"
                            name=""
                            id="registrationStatus"
                            searchwork={props.searchWork.toString()}
                            defaultValue={props.userWork.status}
                            className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                            disabled="false"
                          />
                        )}
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.FileName
                            </EasyTrans>
                          }
                          type="text"
                          name=""
                          id="filename"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.filename}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled="false"
                        />
                        <InputField
                          label={
                            <EasyTrans>MyWorks.WorkListElement.Title</EasyTrans>
                          }
                          type="text"
                          name=""
                          id="title"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.title}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled="false"
                        />
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.WorkDescription
                            </EasyTrans>
                          }
                          type="text"
                          name="test"
                          id="1"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.description}
                          disabled="false"
                        ></InputField>
                        <InputField
                          label={
                            <EasyTrans>MyWorks.WorkListElement.Type</EasyTrans>
                          }
                          type="text"
                          name=""
                          id="type"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.type}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled="false"
                        />
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.Registrar
                            </EasyTrans>
                          }
                          type="text"
                          name=""
                          id="registrar"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.registrar}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled="false"
                        />
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.RegistrationDate
                            </EasyTrans>
                          }
                          type="date"
                          name="registrationDate"
                          id="registrationDate"
                          searchwork={props.searchWork.toString()}
                          defaultValue={moment(props.userWork.registrationDate)
                            .utc()
                            .format("YYYY-MM-DD")}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled="false"
                        />
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.CreationDate
                            </EasyTrans>
                          }
                          type="date"
                          name="creationdate"
                          id="creationdate"
                          searchwork={props.searchWork.toString()}
                          defaultValue={moment(props.userWork.creationDate)
                            .utc()
                            .format("YYYY-MM-DD")}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled={edit}
                        />
                        <InputField
                          label={<EasyTrans>Language</EasyTrans>}
                          type="text"
                          name="language"
                          id="language"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.language}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled={edit}
                        />
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.Identifier
                            </EasyTrans>
                          }
                          type="text"
                          name="identifier"
                          id="identifier"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.identifier}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled={edit}
                        />
                        {(tagsList || !props.searchWork) && (
                          <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              <EasyTrans>
                                MyWorks.WorkListElement.Tags
                              </EasyTrans>
                            </dt>
                            <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {props.userWork.tags.map((Tag) => (
                                <input
                                  type="text"
                                  name="tag"
                                  id="tag"
                                  searchwork={props.searchWork.toString()}
                                  defaultValue={Tag.toString()}
                                  className="flex-1 mb-2 focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                                  disabled={edit}
                                />
                              ))}
                              {tagsList.map((x, index) => {
                                return (
                                  <input
                                    id={x.id}
                                    defaultValue={x.value}
                                    onChange={(e) => {
                                      let newList = tagsList;
                                      newList[index].value = e.target.value;
                                      setTagsList(newList);
                                    }}
                                    type="text"
                                    name="tag"
                                    className="flex-1 mb-2 focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                                    disabled={edit}
                                  />
                                );
                              })}
                              {edit ? (
                                <></>
                              ) : (
                                <AddAndDeleteButton
                                  deleteAllowed={() => tagsList.length >= 1}
                                  onAdd={onAddTag}
                                  onDelete={onDeleteTag}
                                  nameAdd={
                                    <EasyTrans>
                                      MyWorks.WorkListElement.AddTag
                                    </EasyTrans>
                                  }
                                  nameDelete={
                                    <EasyTrans>
                                      MyWorks.WorkListElement.DeleteTag
                                    </EasyTrans>
                                  }
                                ></AddAndDeleteButton>
                              )}
                            </dd>
                          </div>
                        )}
                      </dl>
                    </Collapsible>
                  </div>
                </div>
                <div className="shadow border-fairblue-500 border-2 mb-5 overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <Collapsible
                      trigger={
                        <div className="flex">
                          <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                            <EasyTrans>
                              MyWorks.WorkListElement.LicenseInformation
                            </EasyTrans>
                          </h4>
                          <ArrowDownIcon
                            className="flex-initial h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                      }
                    >
                      <dl className="mt-7">
                        <InputField
                          label={
                            <EasyTrans>
                              MyWorks.WorkListElement.License
                            </EasyTrans>
                          }
                          type="text"
                          name="licence"
                          id="licence"
                          searchwork={props.searchWork.toString()}
                          defaultValue={props.userWork.license.identifier}
                          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                          disabled={edit}
                        />
                        {/* {props.userWork.license_DALICC.toString() !== "" ? (
                          <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              <EasyTrans>
                                MyWorks.WorkListElement.DALICCLizenz
                              </EasyTrans>
                            </dt>
                            <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name="darlicURL"
                                id="darlicURL"
                                defaultValue={props.userWork.license.dallic}
                                className="focus:ring-fairblue focus:border-fairblue enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                                disabled="false"
                              />
                            </dd>
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </dl>
                    </Collapsible>
                  </div>
                </div>

                <div className="shadow border-fairblue-500 border-2 mb-5 overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <Collapsible
                      trigger={
                        <div className="flex">
                          <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                            <EasyTrans>
                              MyWorks.WorkListElement.Participant
                            </EasyTrans>
                          </h4>
                          <ArrowDownIcon
                            className="flex-initial h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                      }
                    >
                      <dl>
                        <div className="bg-white px-4 pt-10 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            <EasyTrans>
                              MyWorks.WorkListElement.Authors
                            </EasyTrans>
                          </dt>
                          <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="shadow mb-2 overflow-hidden sm:rounded-md">
                              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <WorkCreatorFormView
                                  edit={props.editable}
                                  creator={props.userWork.creator}
                                  searchwork={props.searchWork.toString()}
                                  publicWork={props.publicWork}
                                ></WorkCreatorFormView>
                              </div>
                            </div>

                            {participantList.map((x, index) => {
                              return (
                                <div className="shadow mb-2 overflow-hidden sm:rounded-md">
                                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <WorkCreatorFormView
                                      id={x.id}
                                      edit={edit}
                                      creator={x.value}
                                      onCreatorChanged={onCreatorChanged}
                                    ></WorkCreatorFormView>
                                  </div>
                                </div>
                              );
                            })}
                            {edit ? (
                              <></>
                            ) : (
                              <AddAndDeleteButton
                                deleteAllowed={() => creatorList.length >= 2}
                                onAdd={onAddParticipant}
                                onDelete={onDeleteParticipant}
                                nameAdd={
                                  <EasyTrans>
                                    MyWorks.WorkListElement.AddAuthor
                                  </EasyTrans>
                                }
                                nameDelete={
                                  <EasyTrans>
                                    MyWorks.WorkListElement.DeleteAuthor
                                  </EasyTrans>
                                }
                              ></AddAndDeleteButton>
                            )}
                          </dd>
                        </div>
                        {!edit ||
                        !props.userWork.publisher === undefined ||
                        !props.userWork.publisher === "" ? (
                          <div className="bg-white px-4 2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              <EasyTrans>
                                MyWorks.WorkListElement.Publisher
                              </EasyTrans>
                            </dt>

                            <dd className="text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <input
                                type="text"
                                name=""
                                id=""
                                defaultValue={props.userWork.publisher.toString()}
                                className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
                                disabled={edit}
                              />
                            </dd>
                          </div>
                        ) : (
                          <></>
                        )}
                      </dl>
                    </Collapsible>
                  </div>
                </div>
                {!props.publicWork && (
                  <div className="shadow border-fairblue-500 border-2 mb-5 overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <Collapsible
                        trigger={
                          <div className="flex">
                            <h4 className="flex-initial w-full text-lg font-medium leading-6 text-gray-900">
                              <EasyTrans>
                                MyWorks.WorkListElement.MetaInfo
                              </EasyTrans>
                            </h4>
                            <ArrowDownIcon
                              className="flex-initial h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                        }
                      >
                        <dl className="mt-7">
                          <WorkMetaInfoView userWork={props.userWork} />
                        </dl>
                      </Collapsible>
                    </div>
                  </div>
                )}
              </div>
            </dl>
          </Collapsible>
        </div>
      </div>
    </>
  );
};

export default WorkCard;
