import {
  AdjustmentsIcon,
  SearchIcon,
  ViewGridIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

import ApiService from "../../services/ApiService";
import EasyTrans from "./EasyTrans";
import Taglist from "../Taglist";
import { useTranslation } from "react-i18next";

const Controlbar = (props) => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await ApiService.getLicenceList();
        setLicenses(result?.licenses);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <header className="bg-fairgray mx-auto py-2 h-auto">
        <nav
          aria-label="Top"
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex justify-items-center items-center">
              <div className="flex flex-nowrap flex-1">
                <label className="relative block w-full">
                  <span className="sr-only">
                    <EasyTrans>MyWorks.ControlBar.Search</EasyTrans>
                  </span>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <SearchIcon className="w-6 h-6" aria-hidden="true" />
                  </span>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-gray-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-fairblue-500 focus:ring-fairblue-500 focus:ring-1 sm:text-sm"
                    placeholder={t("MyWorks.ControlBar.Search")}
                    onChange={(e) => props.onSetTitle(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        props.handleApplyClick();
                      }
                    }}
                  />
                </label>
              </div>

              <div className=" inset-y-0 right-0 flex items-center pr-2">
                <div className="lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <div
                    onClick={(event) => props.setWorkView(!props.workView)}
                    className="flex cursor-pointer text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white"
                  >
                    <span className="sr-only">
                      <EasyTrans>MyWorks.ControlBar.SrChangeView</EasyTrans>
                    </span>
                    {props.workView ? (
                      <ViewListIcon className="h-8 w-8 rounded-full"></ViewListIcon>
                    ) : (
                      <ViewGridIcon className="h-8 w-8 rounded-full"></ViewGridIcon>
                    )}
                  </div>
                </div>
                <div className="lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <div
                    onClick={(event) => setOpen(!open)}
                    className="flex cursor-pointer text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white"
                  >
                    <AdjustmentsIcon className="h-8 w-8 rounded-full"></AdjustmentsIcon>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-1">
              <div
                className={`bg-white w-full right-0 transition-all duration-200 ${
                  open ? "h-auto" : "h-0 overflow-hidden"
                }`}
              >
                <div className="bg-fairgray w-full right-0 h-auto transition-all duration-200 sm:h-auto sm:overflow-visible md:h-auto md:overflow-hidden">
                  <div className="flex flex-wrap md:flex-nowrap">
                    <div className="w-full md:w-1/3 p-1">
                      <div className="flex flex-col">
                        <div className="p-1">
                          <label className="block font-medium text-gray-700 mb-2">
                            <EasyTrans>
                              RegisterWork.Element.CreationType
                            </EasyTrans>
                          </label>
                          <select
                            className="block w-full bg-white mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm"
                            onChange={(e) =>
                              props.onSetWorkType(e.target.value)
                            }
                          >
                            <option value="">
                              <EasyTrans>MyWorks.ControlBar.NoFilter</EasyTrans>
                            </option>
                            <option value="DOCUMENT">Document</option>
                            <option value="PICTURE">Picture</option>
                            <option value="AUDIO">Audio</option>
                            <option value="VIDEO">Video</option>
                            <option value="INTERACTIVE">Interactive</option>
                            <option value="SOFTWARE">Software</option>
                            <option value="HARDWARE">Hardware</option>
                            <option value="DAT">Data</option>
                          </select>
                        </div>
                      </div>

                      <div className="p-1">
                        <label className="block font-medium text-gray-700 mb-2">
                          <EasyTrans>MyWorks.WorkListElement.License</EasyTrans>
                        </label>
                        <select
                          className="block w-full bg-white mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm"
                          onChange={(e) => props.onSetLicence(e.target.value)}
                        >
                          <option value="">
                            <EasyTrans>MyWorks.ControlBar.NoFilter</EasyTrans>
                          </option>
                          <option value="Full Copyright">
                            <EasyTrans>
                              RegisterWork.License.CommonRightsReserved
                            </EasyTrans>
                          </option>
                          <option value="No Assertion">
                            <EasyTrans>
                              RegisterWork.License.NoAssertion
                            </EasyTrans>
                          </option>
                          {licenses.map((licence) => {
                            return (
                              <option
                                key={licence.referenceNumber}
                                value={licence.licenseId}
                              >
                                {licence.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="p-1">
                        <label className="block font-medium text-gray-700 mb-2">
                          <EasyTrans>Reihenfolge</EasyTrans>
                        </label>
                        <select
                          className="block w-full bg-white mt-1 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fairblue-500 focus:border-fairblue-500 sm:text-sm"
                          onChange={(e) => props.onSetSort(e.target.value)}
                        >
                          <option value="asc">Aufsteigend</option>
                          <option value="desc">Absteigend</option>
                        </select>
                      </div>
                    </div>
                    <div className="w-full h-auto md:w-2/3 p-2">
                      <label className="block font-medium text-gray-700 mb-2">
                        Tags
                      </label>
                      <Taglist
                        setSelectedTags={props.setSelectedTags}
                      ></Taglist>
                    </div>
                  </div>
                </div>

                <div className=" px-2 py-1 bg-gray-50 text-right sm:px-6 ">
                  <button
                    className="inline-flex justify-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fairblue-500 hover:bg-fairdarkgreen hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={props.handleApplyClick}
                  >
                    <EasyTrans>MyWorks.ControlBar.ApplyFilter</EasyTrans>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Controlbar;
