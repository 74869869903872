import React, { useEffect, useState } from "react";
import moment, { lang } from "moment";

import ApiService from "../services/ApiService";
import RegisterCheck from "../components/registerwork/RegisterCheck";
import RegisterWorkConfirmation from "../components/registerwork/registerworkConfirmation";
import RegisterWorkFileUpload from "../components/registerwork/registerworkFileUpload";
import RegisterWorkInformation from "../components/registerwork/registerworkInformation";
import RegisterWorkLicence from "../components/registerwork/registerworkLicence";
import RegisterWorkSave from "../components/registerwork/registerworkSave";
import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";

//TODO Notification when limit in SaveOptions reachedS
//TODO Query frequently used tags and display them as an example for users

const RegisterWorkPage = () => {
  //#region States
  const [registerWorkData, setRegisterWorkData] = useState();

  //#region States Fileupload
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [presignedURL, setPresignedURL] = useState("");
  const [prefix, setPrefix] = useState();
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState("");
  //#endregion
  //#region States Information
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [language, setLanguage] = useState("");
  const [creationDate, setCreationDate] = useState();

  //#region States Involed Persons
  const [contributorList, setContributorList] = useState([
    {
      firstName: UserService.getFirstName(),
      lastName: UserService.getLastName(),
      email: UserService.getEMail(),
      pseudonym: "",
      url: "",
      percentage: 100,
    },
  ]);

  const [publisher, setPublisher] = useState("");
  const [attribution, setAttribution] = useState("");

  //#endregion
  //#region Additional Information
  const [identifier, setIdentifier] = useState("");
  const [relation, setRelation] = useState("");
  //#endregion
  //#endregion
  //#region States License
  const [licence, setLicence] = useState("Full Copyright");
  const [dalicc, setDalicc] = useState("");
  //#endregion
  //#region States SaveOptions
  const [visibility, setVisibility] = useState(true);
  const [saveFileSystem, setSaveFileSystem] = useState(true);
  const [saveIpfsSystem, setSaveIpfsSystem] = useState(false);
  const [saveSelfSave, setSaveSelfSave] = useState(false);
  //#endregion
  //#region States CheckedConfirmation
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  //#endregion
  //#region States Error
  const [fileError, setFileError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [creatorError, setCreatorError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pseudonymError, setPseudonymError] = useState(false);
  const [percentageError, setPercentageError] = useState(false);
  const [licenceError, setLicenceError] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  //#region States RegisterCheck
  const [registerCheckVisible, setRegisterCheckVisibility] = useState(false);
  //#endregion
  //#endregion
  //#endregion

  //#region Methods

  //#region Methods UseEffect
  useEffect(() => {
    // Get the entire query string of the URL
    const queryString = window.location.search;

    // Extracts individual parameters from the query string
    const urlParams = new URLSearchParams(queryString);

    // Reads out the values of the parameters with .get()
    const filename = urlParams.get("filename");
    const prefix = urlParams.get("prefix");

    // Turns off the FileUpload if the file has already been uploaded
    if (filename && prefix) {
      setDisableFileUpload(true);
    }
  }, []);

  //#endregion
  //#region Methods Fileupload
  const onSetPresignedURL = (data) => {
    setPresignedURL(data);
  };
  const onSetPrefix = (data) => {
    setPrefix(data);
  };
  const onSetFile = (data) => {
    setFile(data);
  };
  const onSetFilename = (data) => {
    setFilename(data);
  };
  //#endregion
  //#region Methods Information
  const onSetType = (data) => {
    console.log(data);
    setType(data);
  };
  const onSetTitle = (data) => {
    setTitle(data);
  };
  const onSetDescription = (data) => {
    setDescription(data);
  };
  const onSetTags = (data) => {
    setTags(data);
  };
  const onSetLanguage = (data) => {
    setLanguage(data);
  };
  const onSetCreationDate = (data) => {
    setCreationDate(data);
  };

  //#region Methods Involed Persons
  const onSetContributorList = (data) => {
    setContributorList(data);
  };

  const onSetPublisher = (data) => {
    setPublisher(data);
  };
  const onSetAttribution = (data) => {
    setAttribution(data);
  };

  //#endregion
  //#region Additional Information
  const onSetIdentifier = (data) => {
    setIdentifier(data);
  };
  const onSetRelation = (data) => {
    setRelation(data);
  };
  //#endregion
  //#endregion
  //#region Methods License
  const onSetLicence = (data) => {
    setLicence(data);
  };
  const onSetSaveFileSystem = (data) => {
    setSaveFileSystem(data);
  };
  const onSetDaliccLicence = (data) => {
    setDalicc(data);
  };
  //#endregion
  //#region Methods SaveOptions
  const onSetVisibility = (data) => {
    setVisibility(data);
  };
  const onSetSaveIpfsSystem = (data) => {
    setSaveIpfsSystem(data);
  };
  const onSetSelfSave = (data) => {
    setSaveSelfSave(data);
  };
  //#endregion
  //#region Methods CheckedConfirmation
  const onSetConfirmation = (data) => {
    setConfirmationChecked(data);
  };
  //#endregion
  //#region Methods FinalRegister

  const navigate = useNavigate();

  const prepareType = (type) => {
    return type.toUpperCase();
  };

  const prepareDateData = (date) => {
    return moment(date).toISOString();
  };

  const prepareTagsData = (tags) => {
    let preparedTagsData = [];
    tags.map((x) => preparedTagsData.push(x.value));
    return preparedTagsData;
  };

  const prepareFirstContributor = (contributor) => {
    let preparedContributor = {
      author: {
        id: "",
        firstName: contributor.firstName,
        lastName: contributor.lastName,
        email: contributor.email,
        pseudonym: contributor.pseudonym,
        url: contributor.url,
      },
      percentage: contributor.percentage,
    };

    return preparedContributor;
  };

  const prepareContributor = (contributors) => {
    var preparedContributors = [];
    contributors.shift();
    contributors.map((contributor) =>
      preparedContributors.push({
        author: {
          id: "",
          firstName: contributor.firstName,
          lastName: contributor.lastName,
          email: contributor.email,
          pseudonym: contributor.pseudonym,
          url: contributor.url,
        },
        percentage: contributor.percentage,
      }),
    );
    console.log(preparedContributors);
    return preparedContributors;
  };

  const prepareSaveSystemData = (
    saveFileSystem,
    saveIpfsSystem,
    saveSelfSave,
  ) => {
    let preparedSaveSystemData = [];
    if (saveFileSystem) {
      preparedSaveSystemData.push("FAIRREGISTER_FILE_SYSTEM");
    }

    if (saveIpfsSystem) {
      preparedSaveSystemData.push("IPFS");
    }

    if (saveSelfSave) {
      preparedSaveSystemData.push("OWN_STORAGE");
    }
    return preparedSaveSystemData;
  };

  const prepareVisibility = (visibility) => {
    return visibility ? "PUBLIC" : "PRIVATE";
  };

  const onSendRegister = () => {
    if (!checkValidation()) {
      return;
    }

    const preparedFirstContributor = prepareFirstContributor(
      contributorList[0],
    );
    const preparedContributors = prepareContributor([...contributorList]);
    console.log(preparedFirstContributor);
    console.log(preparedContributors);

    const preparedType = prepareType(type);
    const preparedDateData = prepareDateData(creationDate);
    const preparedTagsData = prepareTagsData(tags);
    const preparedSaveSystemData = prepareSaveSystemData(
      saveFileSystem,
      saveIpfsSystem,
      saveSelfSave,
    );
    const preparedVisibility = prepareVisibility(visibility);

    const preparedUserId = UserService.getUserId();

    setRegisterWorkData({
      type: preparedType,
      title: title,
      description: description,
      tags: preparedTagsData,
      language: language,
      creationDate: preparedDateData,
      registrar: preparedUserId,
      creator: preparedFirstContributor,
      contributors: preparedContributors,
      publisher,
      identifier,
      workFile: {
        prefix,
        filename,
      },
      visibility: preparedVisibility,
      storageOptions: preparedSaveSystemData,
      license: {
        identifier: licence,
        addons: "",
        dual: "",
        dalicc,
      },
    });

    setRegisterCheckVisibility(true);
  };

  function scrollTo(id) {
    console.log(id);
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function checkValidation() {
    var validationCorrect = true;
    var scrollToId = "";
    if (confirmationChecked === false) {
      setConfirmationError(true);
      validationCorrect = false;
    }

    if (!licence) {
      setLicenceError(true);
      scrollToId = "licence";
      validationCorrect = false;
    }

    var validateContributor = contributorList[0];

    var validateFirstName = validateContributor.firstName;
    var validateLastName = validateContributor.lastName;
    var validateEmail = validateContributor.email;
    var validatePseudonym = validateContributor.pseudonym;
    var validatePercentage = validateContributor.percentage;

    if (!validateFirstName) {
      setFirstnameError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!validateLastName) {
      setLastnameError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!validateEmail) {
      setEmailError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!validatePseudonym) {
      setPseudonymError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!validatePercentage) {
      setPercentageError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!contributorList[0]) {
      setCreatorError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!description) {
      setDescriptionError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!title) {
      setTitleError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!type) {
      setTypeError(true);
      scrollToId = "information";
      validationCorrect = false;
    }

    if (!filename) {
      setFileError(true);
      scrollToId = "fileupload";
      validationCorrect = false;
    }

    if (scrollToId) {
      scrollTo(scrollToId);
    }

    return validationCorrect; //CancelPost
  }

  const onGoBack = () => {
    console.log(registerWorkData);

    setRegisterCheckVisibility(false);
  };

  //#endregion

  const onSendRegisterFinally = () => {
    console.log(registerWorkData);

    ApiService.registerWork(registerWorkData).then(() => navigate("/myworks"));
  };
  //#endregion

  //#region View
  return (
    <>
      {registerCheckVisible && (
        <RegisterCheck
          type={type}
          title={title}
          description={description}
          tags={tags}
          language={language}
          creationDate={creationDate}
          creator={contributorList[0].email}
          contributors={[...contributorList]}
          publisher={publisher}
          identifier={identifier}
          filename={filename}
          visibility={visibility}
          storageOptions={prepareSaveSystemData(
            saveFileSystem,
            saveIpfsSystem,
            saveSelfSave,
          )}
          licence={licence}
          onGoBack={onGoBack}
          onSendRegisterFinally={onSendRegisterFinally}
        ></RegisterCheck>
      )}
      <div className={`${!registerCheckVisible ? "" : "hidden"}`}>
        {disableFileUpload ? (
          <></>
        ) : (
          <div id="fileupload">
            <RegisterWorkFileUpload
              onSetFile={onSetFile}
              file={file}
              onSetFilename={onSetFilename}
              filename={filename}
              onSetPresignedURL={onSetPresignedURL}
              onSetPrefix={onSetPrefix}
              prefix={prefix}
              fileError={fileError}
              setFileError={setFileError}
            ></RegisterWorkFileUpload>
          </div>
        )}
        <div id="information"></div>
        <RegisterWorkInformation
          onSetType={onSetType}
          type={type}
          typeError={typeError}
          setTypeError={setTypeError}
          onSetTitle={onSetTitle}
          title={title}
          titleError={titleError}
          setTitleError={setTitleError}
          onSetDescription={onSetDescription}
          description={description}
          descriptionError={descriptionError}
          setDescriptionError={setDescriptionError}
          contributorList={contributorList}
          onSetContributorList={onSetContributorList}
          creatorError={creatorError}
          setCreatorError={setCreatorError}
          firstnameError={firstnameError}
          setFirstnameError={setFirstnameError}
          lastnameError={lastnameError}
          setLastnameError={setLastnameError}
          emailError={emailError}
          setEmailError={setEmailError}
          pseudonymError={pseudonymError}
          setPseudonymError={setPseudonymError}
          percentageError={percentageError}
          setPercentageError={setPercentageError}
          onSetTags={onSetTags}
          tags={tags}
          onSetLanguage={onSetLanguage}
          language={language}
          onSetCreationDate={onSetCreationDate}
          creationDate={creationDate}
          onSetPublisher={onSetPublisher}
          publisher={publisher}
          onSetAttribution={onSetAttribution}
          attribution={attribution}
          onSetIdentifier={onSetIdentifier}
          identifier={identifier}
          onSetRelation={onSetRelation}
          relation={relation}
        ></RegisterWorkInformation>
        <div id="licence"></div>
        <RegisterWorkLicence
          onSetLicence={onSetLicence}
          workType={type}
          licence={licence}
          onSetDaliccLicence={onSetDaliccLicence}
          dalicc={dalicc}
          licenceError={licenceError}
          setLicenceError={setLicenceError}
        ></RegisterWorkLicence>
        <RegisterWorkSave
          onSetVisibility={onSetVisibility}
          visibility={visibility}
          onSetSaveFileSystem={onSetSaveFileSystem}
          saveFileSystem={saveFileSystem}
          onSetSaveIpfsSystem={onSetSaveIpfsSystem}
          saveIpfsSystem={saveIpfsSystem}
          onSetSelfSave={onSetSelfSave}
          saveSelfSave={saveSelfSave}
        ></RegisterWorkSave>
        <RegisterWorkConfirmation
          onSendRegister={onSendRegister}
          onSetConfirmation={onSetConfirmation}
          confirmationChecked={confirmationChecked}
          confirmationError={confirmationError}
          setConfirmationError={setConfirmationError}
        ></RegisterWorkConfirmation>
      </div>
    </>
  );
  //#endregion
};

export default RegisterWorkPage;
