import EasyTrans from "./EasyTrans";
import React from "react";
import InputField from "./InputField";

const WorkMetaInfoView = (props) => {
  var metaInfo = {
    hashFile: props?.userWork?.hashFile?.hexValue,
    hashFileAlgorithm: props?.userWork?.hashFile?.algorithm,
    hashMeta: props?.userWork?.hashMeta?.hexValue,
    hashMetaAlgorithm: props?.userWork?.hashMeta?.algorithm,
    ipfsFileCid: props?.userWork?.ipfsFile?.cid,
    ipfsMetaCid: props?.userWork?.ipfsMeta?.cid,
    visibility: props?.userWork?.visibility,
  };

  return (
    <>
      <div className="mt-7">
        <InputField
          label={<EasyTrans>MyWorks.WorkListElement.HashFile</EasyTrans>}
          type="text"
          name="hashFile"
          id="hashFile"
          defaultValue={metaInfo.hashFile}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={
            <EasyTrans>MyWorks.WorkListElement.HashFileAlgorithm</EasyTrans>
          }
          type="text"
          name="hashFileAlgorithm"
          id="hashFileAlgorithm"
          defaultValue={metaInfo.hashFileAlgorithm}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={<EasyTrans>MyWorks.WorkListElement.HashMeta</EasyTrans>}
          type="text"
          name="hashMeta"
          id="hashMeta"
          defaultValue={metaInfo.hashMeta}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={
            <EasyTrans>MyWorks.WorkListElement.HashMetaAlgorithm</EasyTrans>
          }
          type="text"
          name="hashMetaAlgorithm"
          id="hashMetaAlgorithm"
          defaultValue={metaInfo.hashMetaAlgorithm}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={<EasyTrans>MyWorks.WorkListElement.IpfsFileCid</EasyTrans>}
          type="text"
          name="ipfsFileCid"
          id="ipfsFileCid"
          defaultValue={metaInfo.ipfsFileCid}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={<EasyTrans>MyWorks.WorkListElement.IpfsMetaCid</EasyTrans>}
          type="text"
          name="ipfsMetaCid"
          id="ipfsMetaCid"
          defaultValue={metaInfo.ipfsMetaCid}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
        <InputField
          label={<EasyTrans>MyWorks.WorkListElement.Visibility</EasyTrans>}
          type="text"
          name="visibility"
          id="visibility"
          defaultValue={metaInfo.visibility}
          className="focus:ring-fairblue-500 focus:border-fairblue-500 enabled:hover:ring-green-300 enabled:hover:border-green-300 block w-full shadow-sm sm:text-sm border-gray-300 disabled:border-white rounded-md"
          disabled={true}
        />
      </div>
    </>
  );
};

export default WorkMetaInfoView;
